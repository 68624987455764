import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import theKeepImage from '../../new_images/the_keep.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

import theEyesPoster from '../../new_images/reviews/the-eyes-are-the-best.jpg';
import { connect } from 'react-redux';
import AnthologyAvailable from '../../components/AnthologyAvailable';
import InstagramLink from '../InstagramLink';

class ReviewTheEyes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        // this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">The Eyes Are The Best Part Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={theEyesPoster} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Horror</Link>
                                                        <Link>December 28, 2024</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>The Eyes Are The Best Part (Novel) - Monika Kim</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                                The Eyes Are The Best Part is a great slow burn that intertwines real-world issues into a slasher that gets crazier as you read on. I would definitely recommend this book, and I would definitely read more from this author in the future. 
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR RATING
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            7.5 / 10
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            Would you eat a dead fish’s eyeball? What if your mom offered it to you as a symbol of good luck? There doesn’t seem to be much luck for the main character Ji-Won, whose life is bursting at the seams; Her father has met another woman and left her, her sister, and her mother. Ji-Won’s grades are starting to slip, which could potentially cause her to lose her scholarship and fail out of school. Her mother is losing her mind, waiting by the door for hours to see if her husband will come back to them. Her sister is emotionally distraught and is confused about why this is happening to them. Just when it couldn’t get any worse, her mother meets a man who isn’t exactly a good person and is more of a cockroach, infesting the family and their home. This is when the dreams start. Eyes. Eyes everywhere. Ji-Won starts to fantasize about eyes. How they squish between her teeth. How they burst with a deliciously salty taste (according to her). Her mother’s boyfriend’s eyes are a bright blue, which are irresistible to Ji-Won, causing her to unravel mentally. The thoughts of eyes make her ravenous, and she gets more and more obsessed with each passing chapter.                                            </p>
                                            <p>
                                            There are a ton of slasher and horror books that try to find or expose a problem or movement in society while simultaneously being scary or gory enough to fit in the genre. I feel that one of the successes of this book is the seamless insertion of feminism and racism into the story. For example, the mother’s boyfriend is the pinnacle of misogyny, racism, and cultural fetishization. He talks about how attractive Ji-Won and her sister are, even though they are underage, which adds a new dimension to how disgusting he is. I think this character is one-dimensional for a reason, as to make the reader loathe him and not feel bad for Ji-Won's psychotic outbursts and terrible treatment of him. The mother is a bit one dimensional as well, but I don’t think this was intentional. She never has any development through the whole book, and is a weak, terrified woman who isn’t able to stand up for herself. I am not sure if this is a realistic depiction of someone afraid of breaking out of the chains of sexism and the rigidness of traditional households where the man is the head of the house. 
                                            </p>
                                            <p>
                                            The story is a great slow burn that intertwines real-world issues into a slasher that gets crazier as you read on. It is hard to find big problems with the book, and the little problems that bring it down are tied to the validity of actions and scenes that are part of the plot, so I will not include them in lieu of spoilers. I would definitely recommend this book, and I would definitely read more from this author in the future.                                            
                                            </p>

                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="theeyes" currentUser={this.props.currentUser}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <NewsLetter/>
                            <AnthologyAvailable/>
                            <InstagramLink/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
        </Fragment>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts
    }
}

export default connect (mapStateToProps) (ReviewTheEyes)