import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";

import CommentSectionStory from './CommentSectionStory';
import ThreeRecentStories from '../components/ThreeRecentStories';

import'./StoryPage.css';
import '../components/primary-styles.css';

import tagIcon from '../new_images/pricetag-icon.png';
import whiteTagIcon from '../new_images/icons/white-tag-icon.png';

import eyeIcon from '../new_images/eye-icon.png';
import whiteEyeIcon from '../new_images/icons/white-eye-icon.png';

import thumbIcon from '../new_images/thumbs_up.png';
import whiteThumbIcon from '../new_images/icons/white-thumb-icon.png';
import blueThumbIcon from '../new_images/thumbs/blue-thumb-icon.png';

import darkUserIcon from '../new_images/icons/dark-user-icon.png';

import highlightedCommentsIcon from '../new_images/icons/highlighted-comments-icon.png';
import commentsIcon from '../new_images/icons/comments-icon.png';
import whiteCommentIcon from '../new_images/icons/white-comment-icon.png';

import grayClockIcon from '../new_images/icons/gray-clock-icon.png';

import twitterIcon from '../new_images/sm-logos/twitter-colored-icon.png';
import facebookIcon from '../new_images/sm-logos/facebook-colored-logo.png';
import tumblrIcon from '../new_images/sm-logos/tumblr-colored-icon.png';
import emailIcon from '../new_images/sm-logos/gmail-colored-icon.png';
import shareIcon from '../new_images/icons/share-icon.png';
import linkCircleIcon from '../new_images/icons/link-circle-icon.png';

import shareSmallIcon from '../new_images/icons/share-small-icon.png';
import whiteShareIcon from '../new_images/icons/white-share-icon.png';

import supernaturalImage from '../new_images/category-images/supernatural-default-image.jpg';
import scifiImage from '../new_images/category-images/scifi-default-image.jpg';
import thrillerImage from '../new_images/category-images/thriller-default-image.jpg';
import horrorImage from '../new_images/category-images/horror-default-image.jpg';
import histFicImage from '../new_images/category-images/histfic-default-image.jpg';
import fantasyImage from '../new_images/category-images/fantasy-default-image.jpg';

import hollowStar from '../new_images/icons/hollow-blue-star.png';
import fullStar from '../new_images/icons/full-blue-star.png';

import PromptUserToWrite from './PromptUserToWrite';

import EmailShare from 'react-email-share-link'
import ShareStoryModal from './ShareStoryModal';
import EditStoryModal from './EditStoryModal';
import FavoritedStoryModal from '../modals/FavoritedStoryModal';

import DarkModeToggle from "react-dark-mode-toggle";
import LikesModal from './LikesModal';
import { css } from '@emotion/css';

import MetaTags from 'react-meta-tags';
import { getAnalyticsRef, getDBRef, getInitialDataFB, logPageView, regrabUserInfoFB } from '../FirebaseActions';
import {connect} from 'react-redux';

class StoryPage extends React.Component {
    constructor(props) {
        super(props);

        this.scrollToCommentsRef = React.createRef();
        this.background = React.createRef();
        this.bodyText = React.createRef();
        this.storyText = React.createRef();
        this.headerText = React.createRef();
        this.text = React.createRef();
        this.storyTitle = React.createRef();
        this.shareText = React.createRef();
        this.favoriteText = React.createRef();

        this.db = getDBRef();
        this.state = {
            storyInformation: this.props.allStories.find(element => element.id == (window.location.pathname).slice(7)),
            userInformation: {},
            linkUrl: window.location.pathname,
            scrollPosition: 0,
            showComments: true,
            minutesToRead: 0,
            showShareModal: false,
            commentsIcon: commentsIcon,
            thumbIcon: thumbIcon,
            showDarkMode: false,
            showEditModal: false,
            viewCounted: false,
            wasFavorited: false,
            showFavoritedModal: false,
            showAccountModal: false
        };

        this.scrollToComments = this.scrollToComments.bind(this);
        this.showDarkModeClicked = this.showDarkModeClicked.bind(this);
        this.editAdminClicked = this.editAdminClicked.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.closeEditModalStoryEdited = this.closeEditModalStoryEdited.bind(this);
        this.showLikes = this.showLikes.bind(this);
        this.hideLikes = this.hideLikes.bind(this);
        this.favoriteClicked = this.favoriteClicked.bind(this);
        this.unfavoriteClicked = this.unfavoriteClicked.bind(this);
        this.showAccountModal = this.showAccountModal.bind(this);
      }

      wasSuccess() {
        this.setState(() => { return { loginWasSuccess: true}})
      }

      addView() {
          // Conditional to make sure the person reading the story isn't the author
        if (!this.props.currentUser || (this.props.currentUser.uid != this.state.storyInformation.creatorID)) {
            //console.log(this.state.storyInformation.creatorID);
            //console.log(this.props.currentUser.uid);
            this.db.collection("all-stories").doc(this.state.storyInformation.id).update({
                "allStoryInformation.viewcount": (this.state.storyInformation.allStoryInformation.viewcount + 1)
            });

            let userInformation = this.props.allUsers.find(element => element.uid == this.state.storyInformation.creatorID);

            // Adding to total user views count
            this.db.collection("users").doc(this.state.storyInformation.creatorID).update({
                viewcount: (userInformation.viewcount + 1)
            })
        }
      }

      getAdditionalUserInfo() {
            let userInformation = this.props.allUsers.find(element => element.uid == this.state.storyInformation.creatorID);

            this.setState({userInformation: userInformation});

            if (this.props.currentUser != null && this.props.currentUser.favorites != null && this.props.currentUser.favorites.includes(this.state.storyInformation.id)) {
                this.setState({wasFavorited: true});
            }
      }

      scrollToComments() {
        this.scrollToCommentsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }

      // PROGRESS BAR STUFF BELOW

      listenToScrollEvent() {
        document.addEventListener("scroll", () => {
          requestAnimationFrame(() => {
            this.calculateScrollDistance();
          });
        });
      }

      calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset; // how much the user has scrolled by
        const winHeight = window.innerHeight;
        const docHeight = this.getDocHeight();
    
        const totalDocScrollLength = docHeight - winHeight;
        const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)
    
        //console.log(scrollPostion)
        if (document.getElementById('story-progress-bar') != null) {
            document.getElementById('story-progress-bar').value = scrollPostion;
            //console.log(scrollPostion);
        }

        //console.log(window.screen.width)
        if (document.getElementsByClassName('share-buttons-container')[0] != null && scrollPostion > 60) {
            if (!this.state.viewCounted) {
                //console.log(scrollPostion);
                this.addView();
                this.setState({viewCounted: true});
            }

            document.getElementsByClassName('share-buttons-container')[0].style.display = 'none';
        } else if (document.getElementsByClassName('share-buttons-container')[0] != null && window.screen.width > 767) { document.getElementsByClassName('share-buttons-container')[0].style.display= 'flex'; }

        this.setState({
          scrollPostion,
        });
      }
    
      getDocHeight = () => {
        return Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        );
      }

      // PROGRESS BAR STUFF ABOVE

      linkIconClicked() {
        var copyText = document.createElement("input");
        copyText.type = "text";
        var link = "https://strangestfiction.com/story/" + this.state.storyInformation.id;
        copyText.value = link;
        document.body.appendChild(copyText);
        copyText.select();
        document.execCommand("copy");
        alert("Copied the text: " + copyText.value + " to your clipboard.");
        this.shareClicked('link');
      }

      // Share icon clicked
      shareClicked(type) {
        //console.log(type);

        getAnalyticsRef().logEvent('share_clicked', { type: type});

        if (type === "facebook") {
            this.db.collection("all-stories").doc(this.state.storyInformation.id).update({
                "allStoryInformation.shares.facebook": (this.state.storyInformation.allStoryInformation.shares.facebook + 1)
            });
        } else if (type === "tumblr") {
            this.db.collection("all-stories").doc(this.state.storyInformation.id).update({
                "allStoryInformation.shares.tumblr": (this.state.storyInformation.allStoryInformation.shares.tumblr + 1)
            });
        } else if (type === "twitter") {
            this.db.collection("all-stories").doc(this.state.storyInformation.id).update({
                "allStoryInformation.shares.twitter": (this.state.storyInformation.allStoryInformation.shares.twitter + 1)
            });
        } else if (type === "email") {
            this.db.collection("all-stories").doc(this.state.storyInformation.id).update({
                "allStoryInformation.shares.email": (this.state.storyInformation.allStoryInformation.shares.email + 1)
            });
        } else if (type == "link") {
            this.db.collection("all-stories").doc(this.state.storyInformation.id).update({
                "allStoryInformation.shares.link": (this.state.storyInformation.allStoryInformation.shares.link + 1)
            });
        }

        // Sending shared notification to author
        if (!this.state.currentUser || (this.state.storyInformation.creatorID != this.props.currentUser.uid)) {
            let newNotifications = this.state.userInformation.notifications;
            newNotifications.hasActive = true;
            newNotifications.list.unshift({
                message: `A reader has shared your story '${this.state.storyInformation.allStoryInformation.title}' using ${type}!!`,
                read: false,
                storyId: this.state.storyInformation.id
            })
            this.db.collection("users").doc(this.state.storyInformation.creatorID).update({
                notifications: newNotifications
            })
        }
      }

      // Translates the full tag to the abbreviation
      shortenTag(tag) {
        if (tag === 'Science Fiction') { return 'scifi'}
        else if (tag === 'Supernatural') { return 'supernatural'}
        else if (tag === 'Horror') { return 'horror'}
        else if (tag === 'Fantasy') { return 'fantasy'}
        else if (tag === 'Thriller') { return 'thriller'}
        else if (tag === 'Historical Fiction') { return 'histfic'}
      }

      // Toggle from light to dark mode
      showDarkModeClicked() {
          // Switch to lightmode
          if (this.state.showDarkMode) {

            this.background.current.style.backgroundColor = 'lightgrey';
            this.storyText.current.style.color = 'black';
            this.headerText.current.style.color = 'black';
            this.text.current.style.color = 'black';
            this.storyTitle.current.style.color = 'black';
            this.shareText.current.style.color = 'black';
            this.favoriteText.current.style.color = 'black';

            this.setState({showDarkMode: false, commentsIcon: commentsIcon, thumbIcon: thumbIcon});

          }
          // Switch to darkmode 
          else {

            this.background.current.style.backgroundColor = '#17222B';
            this.storyText.current.style.color = 'white';
            this.headerText.current.style.color = 'white';
            this.text.current.style.color = 'white';
            this.storyTitle.current.style.color = 'white';
            this.shareText.current.style.color = 'white';
            this.favoriteText.current.style.color = 'white';

            this.setState({showDarkMode: true, commentsIcon: whiteCommentIcon, thumbIcon: whiteThumbIcon});

          }
      }

      // Favorite icon was clicked
      favoriteClicked() {
        this.setState({wasFavorited: true, showFavoritedModal: true});

        // Adding the favorite to the stories information
        let favorites = this.state.storyInformation.allStoryInformation.favorites;
        if (favorites == null) {
            favorites = [];
        }
        favorites.push(this.props.currentUser.uid);
        this.db.collection("all-stories").doc(this.state.storyInformation.id).update({
            "allStoryInformation.favorites": favorites
        })

        // Adding the favorite to the users profile
        let favoritedStories = this.props.currentUser.favorites;
        if (favoritedStories == null) {
            favoritedStories = [];
        }
        favoritedStories.push(this.state.storyInformation.id);
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            "favorites": favoritedStories
        });

        // Sending favorited notification to author
        if (this.state.storyInformation.creatorID != this.props.currentUser.uid) {
            let newNotifications = this.state.userInformation.notifications;
            newNotifications.hasActive = true;
            newNotifications.list.unshift({
                message: `${this.props.currentUser.username} has FAVORITED your story '${this.state.storyInformation.allStoryInformation.title}'!!`,
                read: false,
                storyId: this.state.storyInformation.id
            })
            this.db.collection("users").doc(this.state.storyInformation.creatorID).update({
                notifications: newNotifications
            })
        }

        regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
      }

      // Unfavorite a story
      unfavoriteClicked() {
        this.setState({wasFavorited: false});

          // Adding the favorite to the stories information
        let favorites = this.state.storyInformation.allStoryInformation.favorites;
        if (favorites == null) {
            favorites = [];
        }
        favorites = favorites.filter(i => i !== this.props.currentUser.uid);
        this.db.collection("all-stories").doc(this.state.storyInformation.id).update({
            "allStoryInformation.favorites": favorites
        })

        // Adding the favorite to the users profile
        let favoritedStories = this.props.currentUser.favorites;
        if (favoritedStories == null) {
            favoritedStories = [];
        }
        favoritedStories = favoritedStories.filter(i => i !== this.state.storyInformation.id);
        //console.log(favoritedStories);
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            "favorites": favoritedStories
        })

        regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
      }

      // Show the writers that liked the story
      showLikes() {
          if (this.state.storyInformation.allStoryInformation.thumbsUp.length != 0) {
            this.setState({showLikesModal: true});
          }
      }

      hideLikes() {
        this.setState({showLikesModal: false});
      }

      // Edit story from admin perspective
      editAdminClicked() {
        this.setState({showEditModal: true});
      }

      // Close edit from admin perspective
      closeEditModal() {
          this.setState({showEditModal: false});
      }

      closeEditModalStoryEdited() {
        this.setState({showEditModal: false});
      }

      closeAccountModal() {
        this.setState({showAccountModal: false});
      }

      showAccountModal() {
        this.props.dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true,showCreateAccount: true,showSuccess: false,showPassReset: false}});
      }

      componentDidMount() {
        //   console.log(this.props.allStories)
        //   console.log(this.state.storyInformation)
          if (this.state.storyInformation != null) {
            this.getAdditionalUserInfo();
            document.getElementById('article-content').innerHTML = this.state.storyInformation.allStoryInformation.storyText;
            //console.log(this.state.storyInformation.allStoryInformation.storyText.split(' ').length/238);
            this.setState({minutesToRead: Math.round(this.state.storyInformation.allStoryInformation.storyText.split(' ').length/238)})
            if (this.state.storyInformation.allStoryInformation.promptId != null) {
                this.setState({promptInfo: this.props.allPrompts.find(o => o.id == this.state.storyInformation.allStoryInformation.promptId)})
            }
          }

          //console.log(this.state.storyInformation);
          //console.log(this.state.userInformation);
          logPageView('/story');
          this.listenToScrollEvent();
      }

      componentDidUpdate() {
        if (window.location.pathname != this.state.linkUrl) {
            //console.log(this.props.allStories)
            //console.log('not the same')
            this.setState({showComments: false});
            this.setState({storyInformation: this.props.allStories.find(element => element.id == (window.location.pathname).slice(7)), linkUrl: window.location.pathname}, () => {
                this.getAdditionalUserInfo();
                document.getElementById('article-content').innerHTML = this.state.storyInformation.allStoryInformation.storyText;
                this.setState({showComments: true});

                if (this.state.storyInformation.allStoryInformation.promptId != null) {
                    this.setState({promptInfo: this.props.allPrompts.find(o => o.id == this.state.storyInformation.allStoryInformation.promptId)})
                }
            })
        }
      }
    
      render() {
          return (
            <Fragment>
                <MetaTags>
                    <title>{this.state.storyInformation.allStoryInformation.title + " - " + this.state.storyInformation.allStoryInformation.username + " - Strangest Fiction"}</title>
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content={this.state.storyInformation.allStoryInformation.title + " - " + this.state.storyInformation.allStoryInformation.username + " - Strangest Fiction"} />
                    <meta property="og:description" content={this.state.storyInformation.allStoryInformation.description} />
                    <meta property="og:image" content={this.state.storyInformation.allStoryInformation.imgURL} />
                    <meta property="og:type" content="website"></meta>
                    <meta name="description" content={this.state.storyInformation.allStoryInformation.description}></meta>
                    <meta name="keywords" content={this.state.storyInformation.allStoryInformation.tags.join(',')}/>
                </MetaTags>
            {this.state.storyInformation != null && this.state.userInformation != null && (
                <>
                    <div className="hover-story-title-container">
                        {this.state.storyInformation.allStoryInformation.publishAnon && (
                            <div className="hover-story-title">
                                <b>{this.state.storyInformation.allStoryInformation.title}</b> - Anonymous
                            </div>
                        )}
                        {!this.state.storyInformation.allStoryInformation.publishAnon && (
                            <>
                                {this.state.storyInformation.allStoryInformation.fullname != "" && (
                                    <div className="hover-story-title">
                                        <b>{this.state.storyInformation.allStoryInformation.title}</b> - {this.state.storyInformation.allStoryInformation.fullname}
                                    </div>
                                )}
                                {this.state.storyInformation.allStoryInformation.fullname == "" && (
                                    <div className="hover-story-title">
                                        <b>{this.state.storyInformation.allStoryInformation.title}</b> - {this.state.storyInformation.allStoryInformation.username}
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <div className="archives post post1 story-container">

                    <span className="space-30"/>

                    <div className="small-container container">
                        
                        <div className="row background-grey" ref={this.background}>
                            <div className="share-buttons-container">
                                <div ref={this.favoriteText}>Favorite</div>
                                {this.props.currentUser != null && this.state.wasFavorited && (
                                    <a className="favorite-button" onClick={this.unfavoriteClicked}><img src={fullStar} alt="twitter icon" className="favorite-icon"/></a>
                                )}
                                {this.props.currentUser != null && !this.state.wasFavorited && (
                                    <a className="favorite-button" onClick={this.favoriteClicked}><img src={hollowStar} alt="twitter icon" className="favorite-icon"/></a>
                                )}
                                {this.props.currentUser == null && (
                                    <a className="favorite-button" onClick={() => {this.props.dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true, showCreateAccount: true, showSuccess: false,showPassReset: false}})}}><img src={hollowStar} alt="twitter icon" className="favorite-icon"/></a>
                                )}
                                
                                <div ref={this.shareText}>Share</div>
                                <a class="twitter-share-button" href={"https://twitter.com/intent/tweet?text=Check out the short story " + this.state.storyInformation.allStoryInformation.title + "! You can find it on Strangest Fiction: https://strangestfiction.com/story/" + this.state.storyInformation.id} data-size="large" target="_blank" onClick={() => {this.shareClicked('twitter')}}><img src={twitterIcon} alt="twitter icon" className="medium-sm-logo"/></a>
                                <a href={"https://www.facebook.com/sharer/sharer.php?u=https://strangestfiction.com/story/" + this.state.storyInformation.id} class="share-popup" onClick={() => {this.shareClicked('facebook')}} target="_blank"><img src={facebookIcon} alt="twitter icon" className="medium-sm-logo" target="_blank"/></a>
                                <a href={"http://www.tumblr.com/share/link?url=https://strangestfiction.com/story/" + this.state.storyInformation.id} class="share-popup" onClick={() => {this.shareClicked('tumblr')}} target="_blank"><img src={tumblrIcon} alt="tumblr icon" className="medium-sm-logo"/></a>
                                <EmailShare email="mickey@mouse.com" subject={this.state.storyInformation.allStoryInformation.title} body={"Check out the short story " + this.state.storyInformation.allStoryInformation.title + "! You can find it on Strangest Fiction: https://strangestfiction.com/story/" + this.state.storyInformation.id + " "}>
                                    {link => (
                                        <a href={link} data-rel="external" onClick={() => {this.shareClicked('email')}}><img src={emailIcon} alt="email icon" className="medium-sm-logo"/></a>
                                    )}
                                </EmailShare>
                                <a><img src={linkCircleIcon} alt="twitter icon" className="medium-sm-logo" onClick={() => { this.linkIconClicked()}}/></a>
                                {/* <a href={"mailto:?subject=Strangest Fiction Short Story&amp;body=" + emailBody} title="Share by Email"><img src={emailIcon} alt="email icon" className="medium-sm-logo"/></a> */}
                            </div>

                            {this.props.currentUser != null && (
                                <div className="share-icon" onClick={() => {this.setState({showShareModal: true})}} id="share-icon">
                                    <img src={shareIcon} alt="email icon" className="share-img"/>
                                </div>
                            )}
                            {this.props.currentUser == null && (
                                <div className="share-icon" onClick={() => {this.props.dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true, showCreateAccount: true, showSuccess: false,showPassReset: false}});}} id="share-icon">
                                    <img src={shareIcon} alt="email icon" className="share-img"/>
                                </div>
                            )}

                            <div className="col-12 col-md-10 col-lg-8 m-auto">

                                <div className="space-20"/>

                                {this.props.currentUser != null && this.props.currentUser.isAdmin != null && (
                                    <Button variant="primary" size="lg" onClick={this.editAdminClicked}>Edit Story (Admin)</Button>
                                )}

                                {this.state.promptInfo != null && (
                                    <div className='attached-prompt-title'><Link to={`/prompt/${this.state.promptInfo.id}`}>PROMPT: {this.state.promptInfo.title}</Link></div>
                                )}

                                <div className="story-title-row">
                                    <div className="story-title" ref={this.storyTitle}>
                                        {this.state.storyInformation.allStoryInformation.title}
                                    </div>
                                    <DarkModeToggle onChange={ this.showDarkModeClicked } checked={this.state.showDarkMode} size={80}/>
                                </div>

                                <div className="space-20"/>
                                
                                <div className="flex-row-together" ref={this.headerText}>
                                    {this.state.storyInformation.allStoryInformation.publishAnon && (
                                        <div className="flex-row pad-15-right">
                                            <img src={darkUserIcon} alt="anonymous" className="story-card-author-anon-image"/>
                                            <div className="story-page-author-name">Anonymous</div>
                                        </div>
                                    )}

                                    {!this.state.storyInformation.allStoryInformation.publishAnon && (
                                        <div className="flex-row pad-15-right">
                                            {this.state.userInformation.imgUrl != null && (
                                                <img src={this.state.userInformation.imgUrl} alt="user image" className="story-page-author-image"/>
                                            )}
                                            {this.state.userInformation.imgUrl == null && ( 
                                                <img src={darkUserIcon} alt="anonymous" className="story-card-author-anon-image"/>
                                            )}
                                            {this.state.userInformation.isProfilePublic && (
                                                <Link className="story-page-author-name" to={'/user/' + this.state.storyInformation.creatorID}>
                                                    {this.state.storyInformation.allStoryInformation.fullname != "" && (
                                                        <>{this.state.storyInformation.allStoryInformation.fullname}</>
                                                    )}
                                                    {this.state.storyInformation.allStoryInformation.fullname == "" && (
                                                        <>{this.state.storyInformation.allStoryInformation.username}</>
                                                    )}
                                                </Link>
                                            )}
                                            {!this.state.userInformation.isProfilePublic && (
                                                <div className="story-page-author-name">
                                                    {this.state.storyInformation.allStoryInformation.fullname != "" && (
                                                        <>{this.state.storyInformation.allStoryInformation.fullname}</>
                                                    )}
                                                    {this.state.storyInformation.allStoryInformation.fullname == "" && (
                                                        <>{this.state.storyInformation.allStoryInformation.username}</>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <div className="flex-row pad-right-15">
                                        {!this.state.showDarkMode && (
                                            <img src={eyeIcon} alt="Views icon" className="eye-icon" />
                                        )}
                                        {this.state.showDarkMode && (
                                            <img src={whiteEyeIcon} alt="Views icon" className="eye-icon" />
                                        )}
                                        <div className="story-views">{this.state.storyInformation.allStoryInformation.viewcount}</div>
                                    </div>

                                    <div className="flex-row">
                                        {!this.state.showDarkMode && (
                                            <img src={shareSmallIcon} alt="Thumbs up icon" className="eye-icon" />
                                        )}
                                        {this.state.showDarkMode && (
                                            <img src={whiteShareIcon} alt="Thumbs up icon" className="eye-icon" />
                                        )}
                                        <div className="story-views">{this.state.storyInformation.allStoryInformation.shares.facebook + this.state.storyInformation.allStoryInformation.shares.twitter + this.state.storyInformation.allStoryInformation.shares.tumblr + this.state.storyInformation.allStoryInformation.shares.email + this.state.storyInformation.allStoryInformation.shares.link}</div>
                                    </div>

                                    <div className="flex-row comments-button-row pad-right-15" onClick={this.showLikes}
                                    onMouseOver={() => {
                                        this.setState({thumbIcon: blueThumbIcon})
                                    }}
                                    onMouseOut={() => {
                                        if (!this.state.showDarkMode) { this.setState({thumbIcon: thumbIcon}) }
                                        else { this.setState({thumbIcon: whiteThumbIcon}) }
                                    }}>
                                        {!this.state.showDarkMode && (
                                            <img src={this.state.thumbIcon} alt="Thumbs up icon" className="thumb-icon" />
                                        )}
                                        {this.state.showDarkMode && (
                                            <img src={this.state.thumbIcon} alt="Thumbs up icon" className="thumb-icon" />
                                        )}
                                        <div className="story-views">{this.state.storyInformation.allStoryInformation.thumbsUp.length}</div>
                                    </div>

                                    <div className="flex-row comments-button-row pad-right-15" onClick={this.scrollToComments}
                                      onMouseOver={() => {
                                        this.setState({commentsIcon: highlightedCommentsIcon})
                                      }}
                                      onMouseOut={() => {
                                        if (!this.state.showDarkMode) { this.setState({commentsIcon: commentsIcon}) }
                                        else { this.setState({commentsIcon: whiteCommentIcon}) }
                                      }}>
                                        {!this.state.showDarkMode && (
                                            <img src={this.state.commentsIcon} alt="Comments icon" className="eye-icon" />
                                        )}
                                        {this.state.showDarkMode && (
                                            <img src={this.state.commentsIcon} alt="Comments icon" className="eye-icon" />
                                        )}
                                        <div className="story-views">{this.state.storyInformation.allStoryInformation.commentsCount}</div>
                                    </div>

                                    <div className="flex-row">
                                        <img src={grayClockIcon} alt="Clock Icon" className="eye-icon" />
                                        <div className="minutes-to-read">{this.state.minutesToRead} Min.</div>
                                    </div>
                                </div>
                                <div className="padding-top-10"></div>
                                <div className="story-stats">
                                    {this.state.storyInformation.allStoryInformation.tags.map((tag, index2) => {
                                        return (
                                            <Link to={`/category/${this.shortenTag(tag)}`} className="story-tag-live">{tag.toUpperCase()}</Link>
                                        )
                                    })}
                                </div>
                                <div className="date-published" ref={this.text}>
                                    Story Published {new Date(this.state.storyInformation.timestamp).toLocaleDateString("en-US")}
                                </div>

                                {this.state.storyInformation.allStoryInformation.specNote != null && (
                                    <div className='spec-note'>
                                        {this.state.storyInformation.allStoryInformation.specNote}
                                    </div>
                                )}

                                {this.state.storyInformation.allStoryInformation.matureContent && (
                                    <div>
                                        <div className="mature-content-warning">
                                            Warning: Story contains mature content.
                                        </div>
                                    </div>
                                )}

                                {this.state.storyInformation.triggerWarning != null && (
                                    <div>
                                        <div className="mature-content-warning">
                                            {this.state.storyInformation.triggerWarning}
                                        </div>
                                    </div>
                                )}

                                <div className="space-30"/>

                                {this.state.storyInformation.allStoryInformation.imgURL != null && (
                                    <img src={this.state.storyInformation.allStoryInformation.imgURL} alt={"Story image for " + this.state.storyInformation.allStoryInformation.title} className="full-story-image" />
                                )}
                                {this.state.storyInformation.allStoryInformation.imgURL == null && (
                                    <>
                                        {this.state.storyInformation.allStoryInformation.tags[0] === "Horror" && (
                                            <img src={horrorImage} alt={"Horror category default story image."} className="full-story-image" />
                                        )}
                                        {this.state.storyInformation.allStoryInformation.tags[0] === "Thriller" && (
                                            <img src={thrillerImage} alt={"Thriller category default story image."}className="full-story-image" />
                                        )}
                                        {this.state.storyInformation.allStoryInformation.tags[0] === "Fantasy" && (
                                            <img src={fantasyImage} alt={"Fantasy category default story image."} className="full-story-image" />
                                        )}
                                        {this.state.storyInformation.allStoryInformation.tags[0] === "Supernatural" && (
                                            <img src={supernaturalImage} alt={"Supernatural category default story image."} className="full-story-image" />
                                        )}
                                        {this.state.storyInformation.allStoryInformation.tags[0] === "Science Fiction" && (
                                            <img src={scifiImage} alt={"SciFi category default story image."} className="full-story-image" />
                                        )}
                                        {this.state.storyInformation.allStoryInformation.tags[0] === "Historical Fiction" && (
                                            <img src={histFicImage} alt={"HistFic category default story image."} className="full-story-image" />
                                        )}
                                    </>
                                )}

                                <div className="space-30"/>

                                <div class="article-content" id="article-content" ref={this.storyText}>

                                </div>
                                
                            </div>
                        </div>

                        <div className="horizontal-share-buttons" onClick={() => {getAnalyticsRef().logEvent('share_clicked', { type: 'any'}); console.log('clicked')}}>
                                <div className="share-text">Share</div>
                                <a class="twitter-share-button" href={"https://twitter.com/intent/tweet?text=Check out the short story " + this.state.storyInformation.allStoryInformation.title + "! You can find it on Strangest Fiction: https://strangestfiction.com/story/" + this.state.storyInformation.id} data-size="large" target="_blank" onClick={() => {this.shareClicked('twitter')}}><img src={twitterIcon} alt="twitter icon" className="medium-sm-logo"/></a>
                                <a href={"https://www.facebook.com/sharer/sharer.php?u=https://strangestfiction.com/story/" + this.state.storyInformation.id} class="share-popup" onClick={() => {this.shareClicked('facebook')}} target="_blank"><img src={facebookIcon} alt="twitter icon" className="medium-sm-logo" target="_blank"/></a>
                                <a href={"http://www.tumblr.com/share/link?url=https://strangestfiction.com/story/" + this.state.storyInformation.id} class="share-popup" onClick={() => {this.shareClicked('tumblr')}} target="_blank"><img src={tumblrIcon} alt="tumblr icon" className="medium-sm-logo"/></a>
                                <EmailShare email="mickey@mouse.com" subject={this.state.storyInformation.allStoryInformation.title} body={"Check out the short story " + this.state.storyInformation.allStoryInformation.title + "! You can find it on Strangest Fiction: https://strangestfiction.com/story/" + this.state.storyInformation.id + " "}>
                                    {link => (
                                        <a href={link} data-rel="external" onClick={() => {this.shareClicked('email')}}><img src={emailIcon} alt="email icon" className="medium-sm-logo"/></a>
                                    )}
                                </EmailShare>
                                {/* <a href={"mailto:?subject=Strangest Fiction Short Story&amp;body=" + emailBody} title="Share by Email"><img src={emailIcon} alt="email icon" className="medium-sm-logo"/></a> */}
                        </div>

                        <div className="space-30"/>

                        {!this.state.storyInformation.allStoryInformation.publishAnon && this.state.userInformation.description != "" && (
                            <div className="author-bio-container">
                                <img src={this.state.userInformation.imgUrl} alt="profile image" className="author-bio-image"/>
                                <div className="author-bio-name-desc">
                                    {this.state.storyInformation.allStoryInformation.fullname != "" && (
                                        <div className="author-bio-name">
                                            Written by <Link to={'/user/' + this.state.storyInformation.creatorID}>{this.state.userInformation.fullname}</Link>
                                        </div>
                                    )}
                                    {this.state.storyInformation.allStoryInformation.fullname == "" && (
                                        <div className="author-bio-name">
                                            Written by <Link to={'/user/' + this.state.storyInformation.creatorID}>{this.state.userInformation.username}</Link>
                                        </div>
                                    )}
                                    <div className="author-bio-desc">
                                        {this.state.userInformation.description}
                                    </div>
                                </div>
                            </div>
                        )}

                        <FavoritedStoryModal showModal={this.state.showFavoritedModal} closeModal={() => { this.setState({showFavoritedModal: false})}} storyInformation={this.state.storyInformation} />

                        <ShareStoryModal regrabUserInfo={() => { regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid)}} scrollToComments={() => {this.scrollToComments()}} currentUser={this.props.currentUser} authorInfo={this.state.userInformation} storyInformation={this.state.storyInformation} showModal={this.state.showShareModal} closeModal={() => {this.setState({showShareModal: false})}}/>
                        <div ref={this.scrollToCommentsRef}></div>
                        <div className="space-30"/>
                        {this.state.showComments && (
                            <CommentSectionStory showAccountModal={this.showAccountModal}  authorInfo={this.state.userInformation} reloadAllStories={() => {getInitialDataFB(this.props.dispatch)}} allUsers={this.props.allUsers} story={this.state.storyInformation} currentUser={this.props.currentUser}/>
                        )}
                        <div className="space-30"/>
                        <PromptUserToWrite/>
                        <div className="space-30"/>
                    <ThreeRecentStories allStories={this.props.allStories} allUsers={this.props.allUsers} history={this.props.history}/>
                    </div>
                    <div class="meter">
                                <progress id="story-progress-bar" value={0} max={100} className="story-progress-bar"> </progress>
                            </div>
                </div>
            </>
            )}
            {this.state.storyInformation == null && (
                <div className="story-not-found-container">
                    <h1>Story not found!</h1>
                    <p>We couldn't find the story you were looking for.</p>
                    <Button variant="primary" size="lg" onClick={() => {this.props.history.push("/")}}>Return to Home</Button>
                </div>
            )}

            {this.state.showEditModal && (
                <EditStoryModal allPrompts={this.props.allPrompts} savedInfo={this.state.storyInformation} showModal={this.state.showEditModal} closeModal={() => {this.closeEditModal()}} closeEditModalStoryEdited={this.closeEditModalStoryEdited} currentUser={this.state.userInformation}/>
            )}

            {this.state.showLikesModal && (
                <LikesModal showModal={this.state.showLikesModal} closeModal={this.hideLikes} likes={this.state.storyInformation.allStoryInformation.thumbsUp} allUsers={this.props.allUsers} />
            )}
            
        </Fragment>
          )
      }
}

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts
    }
}

export default connect (mapStateToProps) (StoryPage)