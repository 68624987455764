import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import NewsLetter from "../components/NewsLetter";
import InstagramLink from './InstagramLink';

import '../components/primary-styles.css';

import CategoriesWidget from '../components/CategoriesWidget';


export default class TopStoriesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <CategoriesWidget/>
                                <NewsLetter/>
                                <InstagramLink/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};