import React, { useState, useEffect, useRef } from 'react';
import { toFullMonth } from '../utils/commonFunctions';

import { useHistory } from 'react-router-dom';

import Button from "react-bootstrap/Button";

import tagIcon from '../new_images/pricetag-icon.png';
import eyeIcon from '../new_images/icons/blue-eye-icon.png';
import thumbIcon from '../new_images/icons/white-thumb-icon.png';
import commentsIcon from '../new_images/icons/white-comment-icon.png';
import shareSmallIcon from '../new_images/icons/white-share-icon.png';
import darkUserIcon from '../new_images/icons/dark-user-icon.png';

import spookyEyesBackground from '../new_images/spooky-eyes-background.jpg';
import supernaturalImage from '../new_images/category-images/supernatural-default-image.jpg';
import scifiImage from '../new_images/category-images/scifi-default-image.jpg';
import thrillerImage from '../new_images/category-images/thriller-default-image.jpg';
import horrorImage from '../new_images/category-images/horror-default-image.jpg';
import histFicImage from '../new_images/category-images/histfic-default-image.jpg';
import fantasyImage from '../new_images/category-images/fantasy-default-image.jpg';
import { Award, AwardFill } from 'react-bootstrap-icons';

import './StoryPreview.css';
import { css } from '@emotion/css';

function StoryPreview(props) {

    const history = useHistory();

    const story = props.story;
    const userInfo = props.userInfo;
    
    const date = new Date(story.timestamp);
    const dateString = toFullMonth(date.getMonth()) + " " + date.getDate() + ", " + date.getFullYear();

    const storyClicked = (story) => {
        history.push('/story/' + story.id);
    }

    // console.log(story);

    return (
        <div className={`col-sm-${props.colWidth + 2} col-md-${props.colWidth} story-col`}>
            
            {props.winner && (
                <>
                    <div className={css`display: flex; flex-direction: row; justify-content: center; padding-top: 10px;`}>
                        <AwardFill className={css`margin-top: 5px;`} color={"lightgreen"}/>
                        <div className={css`color: lightgreen;`}>COMPETITION WINNER</div>
                    </div>
                </>
            )}

            {props.honMen && (
                        <div className={css`display: flex; flex-direction: row; justify-content: center; padding-top: 10px;`}>
                            <Award className={css`margin-top: 5px;`}/>
                            <div className={css`font-size: 16px;`}>HONORABLE MENTION</div>
                        </div>
                    )}

            <div className="story-card-container">
                <div class="story-card" onClick={() => {storyClicked(story)}}>

                        <div>
                            {story.allStoryInformation.imgURL != null && (
                                <img src={story.allStoryInformation.imgURL} alt={"Story image for " + story.allStoryInformation.title} className="sample-story-image" />
                            )}
                            {story.allStoryInformation.imgURL == null && (
                                <>
                                    {story.allStoryInformation.tags[0] === "Horror" && (
                                        <img src={horrorImage} alt={"Horror category default story image."} className="sample-story-image" />
                                    )}
                                    {story.allStoryInformation.tags[0] === "Thriller" && (
                                        <img src={thrillerImage} alt={"Thriller category default story image."} className="sample-story-image" />
                                    )}
                                    {story.allStoryInformation.tags[0] === "Fantasy" && (
                                        <img src={fantasyImage} alt={"Fantasy category default story image."} className="sample-story-image" />
                                    )}
                                    {story.allStoryInformation.tags[0] === "Supernatural" && (
                                        <img src={supernaturalImage} alt={"Supernatural category default story image."} className="sample-story-image" />
                                    )}
                                    {story.allStoryInformation.tags[0] === "Science Fiction" && (
                                        <img src={scifiImage} alt={"SciFi category default story image."} className="sample-story-image" />
                                    )}
                                    {story.allStoryInformation.tags[0] === "Historical Fiction" && (
                                        <img src={histFicImage} alt={"Historical Fiction category default story image."} className="sample-story-image" />
                                    )}
                                    {story.allStoryInformation.tags.length == 0 && (
                                        <img src={supernaturalImage} alt={"Supernatural category default story image."} className="sample-story-image" />
                                    )}
                                </>
                            )}

                            <div className="story-preview-content">
                                    <div className="flex-row">
                                        <div className="story-card-title">{story.allStoryInformation.title}</div>
                                    </div>

                                    {/* <div className="tags-row">
                                        {story.allStoryInformation.tags.map((tag, index2) => {
                                            return (
                                                <div className="category-tag">
                                                    <img src={tagIcon} alt="Tag Icon" className="tag-icon" />
                                                    <div className="story-tag">{tag}</div>
                                                </div>
                                            )
                                        })}
                                    </div> */}

                                    <div className="story-stats">
                                        <div className="flex-row">
                                            <img src={eyeIcon} alt="View count" className="eye-icon-small" />
                                            <div className="story-views-small-blue">{story.allStoryInformation.viewcount}</div>
                                        </div>
                                        <div className="flex-row">
                                            <img src={shareSmallIcon} alt="View count" className="eye-icon-small" />
                                            <div className="story-views-small">{story.allStoryInformation.shares.facebook + story.allStoryInformation.shares.twitter + story.allStoryInformation.shares.tumblr + story.allStoryInformation.shares.email + story.allStoryInformation.shares.link}</div>
                                        </div>
                                        <div className="flex-row">
                                            <img src={thumbIcon} alt="Thumbs up count" className="eye-icon-small" />
                                            {story.allStoryInformation.thumbsUp != null && (
                                                <div className="story-views-small">{story.allStoryInformation.thumbsUp.length}</div>
                                            )}
                                            {story.allStoryInformation.thumbsUp == null && (
                                                <div className="story-views-small">0</div>
                                            )}
                                        </div>
                                        <div className="flex-row">
                                            <img src={commentsIcon} alt="View count" className="eye-icon-small" />
                                            <div className="story-views-small">{story.allStoryInformation.commentsCount}</div>
                                        </div>
                                        {story.allStoryInformation.tags.map((tag, index2) => {
                                            return (
                                                <div className="story-tag">{tag.toUpperCase()}</div>
                                            )
                                        })}
                                    </div>

                                    <div className="date-published-preview">
                                        {dateString}
                                    </div>

                            </div>

                        </div>
                        

                        {window.screen.width < 1200 && (
                            <div className="story-preview-content">
                                <div className="story-card-body">{story.allStoryInformation.description}</div>
                            </div>
                        )}

                        {window.screen.width >= 1200 && props.topStory == null && (
                            <div className="story-preview-content">
                                <div className="story-card-body">{story.allStoryInformation.description}</div>
                            </div>
                        )}

                        <div className="story-preview-content">
                            
                            <div className="flex-row wrap-me">
                                <div className="flex-row">
                                    {story.allStoryInformation.publishAnon == false && (
                                        <>
                                            {userInfo.imgUrl != null && (
                                                    <img src={userInfo.imgUrl} alt="User image" className="story-card-author-image"/>
                                            )}

                                            {userInfo.imgUrl == null && (
                                                <img src={darkUserIcon} alt="User image" className="story-card-author-image"/>
                                            )}

                                            {story.allStoryInformation.fullname != "" && (
                                                <div className="story-card-author-name">{story.allStoryInformation.fullname}</div>
                                            )}

                                            {story.allStoryInformation.fullname == "" && (
                                                <div className="story-card-author-name">{story.allStoryInformation.username}</div>
                                            )}
                                        </>
                                    )}
                                    {story.allStoryInformation.publishAnon == true && (
                                        <>
                                            <img src={darkUserIcon} alt="User image" className="story-card-author-image"/>
                                            <div className="story-card-author-name">Anonymous</div>
                                        </>
                                    )}
                                </div>

                                {/* {props.favoritePreview != null && (
                                    <div className="center-content padding-top-10">
                                        <Button variant="primary" className="pad-right-10" onClick={(e) => {e.preventDefault(); props.unfavoriteStory()}}>
                                            Unfavorite
                                        </Button>
                                    </div>
                                )} */}
                                {/* <div className="date-published-preview">
                                    {new Date(story.timestamp).toLocaleDateString("en-US")}
                                </div> */}
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default StoryPreview;