import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const NotificationsModal = (props) => {
        return ( 
            <Modal show={props.showModal} onHide={props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Unread Notifications
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-content">
                        <div className="short-description">
                            You have unread messages or notifications! Go to your account page to see them.
                        </div>
                    </div>
                    <div className="center-content">
                        <Button variant="primary" size="lg" className="pad-right-10" onClick={() => {props.closeModal()}}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
}
export default NotificationsModal;