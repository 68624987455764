import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";

import '../../components/primary-styles.css';

import '../NewsletterPage.css';
import StrangestFictionPoster from '../../new_images/branding/StrangestFictionFinalVersion.png';
import { css, cx } from '@emotion/css';
import SideFeedContainer from '../SideFeedContainer';


const MerchPage = (props) => {

    return (
        <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h3>Merch</h3>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="businerss_news">
                                    <div className='row'>
                                        <div className={`col-sm-6 col-md-4`}>
                                            <a href="https://www.ebay.com/itm/314213249485?mkcid=16&mkevt=1&mkrid=711-127632-2357-0&ssspo=jg6g3vlprtq&sssrc=2047675&ssuid=&widget_ver=artemis&media=COPY">
                                                <img src={StrangestFictionPoster} alt="temp icon" className={css``}/>
                                                <b>Strangest Fiction 2" x 2" Sticker</b>
                                                <div>$2.32</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <SideFeedContainer showCategories={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    )
}

export default MerchPage;