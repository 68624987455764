import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import InstagramLink from './InstagramLink';

import '../components/primary-styles.css';
import './AboutUsPage.css';

import {Link} from "react-router-dom";
import { logPageView } from '../FirebaseActions';


export default class AboutUsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            topTenStories: null
        };
    }

    componentDidMount() {
        logPageView(window.location.pathname);
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="about-top-background padding-top-50">
                    <div className="container">
                        <h1 className="about-us-title">About Strangest Fiction</h1>

                    </div>
                </div>
                <div className="container">

                    <h1 className="about-us-section-title">Mission</h1>

                    <div className="about-us-description">
                        Strangest Fiction is an independent publishing house that invests in writers of all backgrounds and continuously develops itself as a gateway to the traditional publishing world of horror, thriller, fantasy, and science fiction writing.
                    </div>

                    {/* <h1 className="about-us-section-title">Our Goal</h1>

                    <div className="about-us-description">
                        We believe that stories are the gateway to the soul and want to share with our readers insight into all forms of human emotion through mysterious, terrifying, and curious stories.
                        Our goal is to help novice writers explore their passion in a supportive community of readers and fellow writers who are excited about the prospect of sharing “strange” short fiction with the world. 
                    </div> */}

                    <div class="allMissionStuff">
                            <div class="arrow_box">
                                <div class="mission">
                                    Strange - (adjective) unusual or surprising in a way that is unsettling or hard to understand.
                                </div>
                            </div>
                    </div>

                    <h1 className="about-us-section-title">What exactly does “strange” fiction mean? </h1>

                    <div className="about-us-description">
                        That is up to the reader's interpretation. To us, “strange” applies to stories that are aimed at igniting terror, bewilderment, curiosity, passion, and any other emotion imaginable in the reader beyond that of a more “ordinary” story. While we don’t think there is anything wrong with a more traditional piece of fiction (and they certainly have their place), our goal is to create a community in which writers feel no restrictions with the type of content they write.

                        While we do expect writers to warn readers if a story contains mature content, there are no content suggestions for what a story should look like. Explore your heart, your surroundings, your passions, and tell the community what you see.

                    </div>

                    <h1 className="about-us-section-title">How do I write stories? </h1>

                    <div className="about-us-description">
                        To get started writing, create an account if you don’t already have one and then click the blue write button in the navbar. You’ll be able to select a title, description, and as many categories as are applicable. You can share an unlimited amount of stories and get feedback from the Strangest Fiction community. You can track the stories progress in the account tab to see how many likes, comments, and views your stories have. We are so excited to read your stories!

                    </div>

                    <h1 className="about-us-section-title">More questions? </h1>

                    <div className="about-us-description">
                        Send us a message <Link to="/contact">here</Link>

                    </div>

                </div>
            </Fragment>
        );
    }
};