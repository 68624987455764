import React, {Fragment, useEffect, useRef, useState} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import NewsLetter from "../components/NewsLetter";
import InstagramLink from './InstagramLink';
import {Link} from 'react-router-dom';

import './PromptsListPage.css';

import tagIcon from '../new_images/pricetag-icon.png';
import eyeIcon from '../new_images/eye-icon.png';
import thumbIcon from '../new_images/thumbs_up.png';
import darkUserIcon from '../new_images/icons/dark-user-icon.png';

import CategoriesWidget from '../components/CategoriesWidget';
import PromptUserToWrite from './PromptUserToWrite';
import { PayPalDonationButton } from '../components/PayPalDonationButton';

import './NewsletterPage.css';

import FillOutSurvey from '../components/FillOutSurvey';
import TwitterFeed from '../components/TwitterFeed';
import SideFeedContainer from './SideFeedContainer';
import {connect} from 'react-redux';

// const prompts = [
//     {
//         title: 'Write a story in just 20 minutes!',
//         timestamp: 1639782361000,
//         storyIds: [
//             'hu8ZcPCxu1EnamQZtRRj',
//             'AamlzHbNq2o2kNGlY7jW'
//         ]
//     },
//     {
//         title: 'Write a story about someone that can run at insane speeds but cannot slow down!',
//         timestamp: 1635807961000,
//         storyIds: [
//             '2CxOdSYdbOt22MvDmSDY'
//         ]
//     },
//     {
//         title: 'Write a story about a robot trying to create a human to repopulate the earth.',
//         timestamp: 1637622361000,
//         storyIds: [
//             'TCNSVF42TO5n4nKoayQl'
//         ]
//     },
//     {
//         title: 'Latest.',
//         timestamp: 34,
//         storyIds: [
//             'TCNSVF42TO5n4nKoayQl'
//         ]
//     },
//     {
//         title: 'Write a story in one hour.',
//         timestamp: 1644361561000,
//         storyIds: []
//     }
// ]

class PromptsListPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortedPrompts: null
        };

        this.sortSelector = React.createRef();

        this.sortClicked = this.sortClicked.bind(this);
    }

    sortClicked () {
        //console.log(this.sortSelector.current.value);
        //(this.state.sortedPrompts);
        if (this.sortSelector.current.value == 'date') {
            this.setState({sortedPrompts: this.state.sortedPrompts.sort((a, b) => { return b.timestamp - a.timestamp;})})
        } else if (this.sortSelector.current.value == 'popularity') {
            this.setState({sortedPrompts: this.state.sortedPrompts.sort((a, b) => { return b.storyIds.length - a.storyIds.length;})})
        }
    }

    componentDidMount() {
        this.setState({sortedPrompts: this.props.allPrompts.sort((a, b) => { return b.timestamp - a.timestamp;})});
    }

    render () {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h3>Prompts ({this.props.allPrompts.length})</h3>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="sort-by-row">
                                    <div className="sort-by-container">
                                        <div className="sort-by">Sort By:</div>
                                        <select className="sorting-dropdown" name="options" id="sort-selector" onChange={this.sortClicked} ref={this.sortSelector}>
                                            <option value="date">Recently Added</option>
                                            <option value="popularity">Most Popular</option>
                                        </select>
                                    </div>
                                </div>
    
                                <div className="wrapper-40">
                                    {this.state.sortedPrompts != null && this.state.sortedPrompts.map((prompt, index) => {
                                        //console.log(this.state.sortedPrompts)
                                        return (
                                            <Link className="prompt-container" to={`/prompt/${prompt.id}`}>
                                                <div className="prompt-title">
                                                    {prompt.title}
                                                </div>
                                                <div className="prompt-description">
                                                    {prompt.storyIds.length} Submission(s) - Created {new Date(prompt.timestamp).toLocaleDateString("en-US")}
                                                </div>
                                            </Link>
                                        )
                                    })}
                                    <PromptUserToWrite/>
                                </div>
    
                            </div>
                            <div className="col-lg-3">
                                <SideFeedContainer/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allPrompts: state.primary.allPrompts
    }
}

export default connect (mapStateToProps) (PromptsListPage)