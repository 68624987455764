import React, {Fragment} from "react";
import {toast, ToastContainer} from "react-toastify";
import Routes from "./__Routes";
import ScrollTopButton from "../components/ScrollTopButton";
import Spinner from "react-bootstrap/Spinner";

import EmailVerifiedModal from './EmailVerifiedModal';
import PromptCreateAccountModal from './PromptCreateAccountModal';
import AccountModalContainer from './AccountModalContainer';
import ExitPageModal from './ExitPageModal';
import NotificationsModal from "../modals/NotificationsModal";

import './AppContainer.css';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';

// Redux stuff
import { connect } from 'react-redux';
import store from './ReduxStore/index';
import { compose } from "redux";
import { defaultAction } from "../store/actions";

import { getAllStoriesFB, getInitialDataFB, onAuthenticateFB } from '../FirebaseActions.js';

const config = {
    apiKey: "AIzaSyBzf6ALgxHclGrGiU4DJlD5FRtQMhr-IKA",
    authDomain: "strangestfiction.com",
    projectId: "strangest-fiction",
    storageBucket: "strangest-fiction.appspot.com",
    messagingSenderId: "598195654626",
    appId: "1:598195654626:web:458143051d068b516f33bb",
    measurementId: "G-N5R87KMYZG"
};

class AppContainer extends React.Component {
    constructor(props) {
        super(props);

        // this.state = {       
        //     currentUser: null,
        //     checkForUser: false,
        //     allStories: null,
        //     emailVerified: true,
        //     showPromptCreateAccount: false,
        //     showAccountModal: false,
        //     showExitPageModal: false,
        //     currentUserId: null,
        //     allPrompts: null,
        //     showNotificationsPopup: false
        // };
    }

    async componentDidMount() {
        onAuthenticateFB(this.props.dispatch);
        getInitialDataFB(this.props.dispatch);

        // document.addEventListener('mouseout', e => {
        //     if (this.state.currentUser == null && this.state.showPromptCreateAccount == false && (e.clientY <= 0 || e.clientX <= 0 || (e.clientX >= window.innerWidth || e.clientY >= window.innerHeight))) {
        //         this.setState({showExitPageModal: true});
        //     }
        // });
    }

    // reloadAllStories = getInitialDataFB

    render() {
        return ( 
            <>
                {this.props.checkForUser && this.props.allStories != null && this.props.allUsers != null && this.props.allPrompts != null && !this.props.isLoading && (
                    <Fragment>
                        <Routes/>
                        <ToastContainer position="top-center"/>

                        <EmailVerifiedModal showModal={!this.props.emailVerified} closeModal={() => {this.props.dispatch({type: "SET_EMAIL_VERIFIED", payload: true})}}/>
                        <PromptCreateAccountModal showModal={this.props.showPromptCreateAccountModal} closeModal={() => {this.props.dispatch({type: "SET_PROMPT_CREATE_ACCOUNT_MODAL", payload: false})}} createAccountClicked={() => {this.setState({showPromptCreateAccount: false, showAccountModal: true})}}/>
                        <AccountModalContainer/>
                        {/* <ExitPageModal closeModal={() => {this.setState({showExitPageModal: false})}} showModal={this.state.showExitPageModal} createAccount={() => {this.setState({showExitPageModal: false, showAccountModal: true})}}/> */}
                        <NotificationsModal showModal={this.props.showNotificationsPopup} closeModal={() => {this.props.dispatch({type: "SET_NOTIFICATIONS_MODAL", payload: false})}} />

                        <ScrollTopButton/>
                    </Fragment>
                )}
                {this.props.isLoading && (
                    <Fragment>
                        <div class="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" className="loading-spinner">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        </div>
                    </Fragment>
                )}
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        allPrompts: state.primary.allPrompts,
        checkForUser: state.primary.checkForUser,
        emailVerified: state.primary.emailVerified,
        showPromptCreateAccountModal: state.primary.otherModals.showPromptCreateAccountModal,
        showNotificationsPopup: state.primary.otherModals.showNotificationsPopup,
        isLoading: state.primary.isLoading
    }
}

export default connect (mapStateToProps) (AppContainer)