import React, {useEffect} from "react";

export const PayPalDonationButton = () => {

    useEffect(() => {
        window.PayPal.Donation.Button({
            env:'production',
            hosted_button_id:'U4LG3HKKKJFX8',
            image: {
                src:'https://pics.paypal.com/00/s/Y2UyMDEwOTYtNzkxYS00ZDVjLThlZTYtZWE3Y2QzYjg3MWYy/file.PNG',
                alt:'Donate with PayPal button',
                title:'Donate - Donate to support the development of Strangest Fiction!',
            }
        }).render('#donate-button');
    }, [])

    return (
        <div id="donate-button-container">
            <div id="donate-button" className="donate-button"></div>
        </div>
    )
}