import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import NewsLetter from "../components/NewsLetter";
import InstagramLink from './InstagramLink';

import '../components/primary-styles.css';

import CategoriesWidget from '../components/CategoriesWidget';
import { PayPalDonationButton } from '../components/PayPalDonationButton';
import './ContactUs.css';

import MessageSentModal from './MessageSentModal';
import { getDBRef, logPageView } from '../FirebaseActions';


export default class ContactUs extends React.Component {

    constructor(props) {
        super(props);

        this.emailRef = React.createRef();
        this.nameRef = React.createRef();
        this.subjectRef = React.createRef();
        this.messageRef = React.createRef();
        this.missingFieldError = React.createRef();

        this.state = {
            topTenStories: null,
            showModal: false
        };

        this.submitClicked = this.submitClicked.bind(this);
    }

    async submitClicked() {
        //console.log(this.emailRef.current.value);
        let db = getDBRef();

        if (this.emailRef.current.value != "" && this.nameRef.current.value != ""
        && this.subjectRef.current.value != "" && this.messageRef.current.value != "") {
            let currentDate = new Date();

            let emailInfo = {
                name: this.nameRef.current.value,
                email: this.emailRef.current.value,
                subject: this.subjectRef.current.value,
                message: this.messageRef.current.value,
                date: currentDate
            }
    
            // const response = await fetch('https://us-central1-strangest-fiction.cloudfunctions.net/sendMail', {
            //     method: 'POST',
            //     headers: {'Content-Type' : 'application/json'},
            //     body: JSON.stringify(emailInfo)
            // });

            db.collection("contact-us").doc(emailInfo.subject).set({
                message: emailInfo
            })
    
            //console.log(response)

            this.setState({showModal: true});
        } else {
            this.missingFieldError.current.style.display="block";
        }
    }
    componentDidMount() {
        logPageView(window.location.pathname);
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h1>Contact Us</h1>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="businerss_news">
                                    <h5>Send us a message with any questions about the site or issues you are having with the site! We will respond as soon as possible.</h5>
                                    <div className="space-40"/>
                                    <div className={`box widget news_letter mb30`}>
                                        <div className="signup_form">
                                            <input className={`signup`} type="email" placeholder="Your name" ref={this.nameRef}/>
                                            <div className="space-10"/>
                                            <input className={`signup`} type="email" placeholder="Your email" ref={this.emailRef}/>
                                            <div className="space-10"/>
                                            <input className={`signup`} type="email" placeholder="Subject" ref={this.subjectRef}/>
                                            <div className="space-10"/>
                                            <textarea className={`signup`} type="email" placeholder="Message" ref={this.messageRef} rows="5"/>
                                            <div className="error-message" ref={this.missingFieldError}>
                                                Your story must have a description!
                                            </div>
                                        </div>
                                        <div className="submit-contact-container">
                                            <div className="submit-contact-button" onClick={this.submitClicked}>
                                                Submit
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <CategoriesWidget/>
                                <PayPalDonationButton/>
                                <NewsLetter/>
                                <InstagramLink/>
                            </div>
                        </div>
                    </div>
                </div>
                <MessageSentModal showModal={this.state.showModal} closeModal={() => { this.setState({showModal: false})}}/>
            </Fragment>
        );
    }
};