import React from 'react';
import { Timeline } from 'react-twitter-widgets';
import { css } from '@emotion/css';

const TwitterFeed = () => {
    return (
        <div className={css`padding-top: 10px; `}>
            <Timeline
                dataSource={{
                    sourceType: 'url',
                    url: 'https://twitter.com/StrangestFict'
                }}
                options={{
                    height: '400'
                }}
            />
        </div>
    )
}

export default TwitterFeed;