import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";

import '../components/primary-styles.css';
import './WritersPage.css';

import eyeIcon from '../new_images/icons/blue-eye-icon.png';
import thumbIcon from '../new_images/icons/white-thumb-icon.png';
import commentsIcon from '../new_images/icons/white-comment-icon.png';
import darkUserIcon from '../new_images/icons/dark-user-icon.png';
import bluePenIcon from '../new_images/icons/blue-pen-icon.png';
import adminIcon from '../new_images/icons/admin-icon.png';
import PromptUserToWrite from './PromptUserToWrite';
import SideFeedContainer from './SideFeedContainer';
import {connect} from 'react-redux';
import {logPageView} from '../FirebaseActions';

class WritersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortedUsers: null
        };

        this.sortSelector = React.createRef();

        this.sortClicked = this.sortClicked.bind(this);
    }

    sortClicked() {
        //console.log(this.sortSelector.current.value);
        if (this.sortSelector.current.value == 'number') {
            this.setState({sortedUsers: this.state.sortedUsers.sort((a, b) => { return b.numstories - a.numstories;})});
        } else if (this.sortSelector.current.value == 'reads') {
            this.setState({sortedUsers: this.state.sortedUsers.sort((a, b) => { return b.viewcount - a.viewcount})});
        } else if (this.sortSelector.current.value == 'reactions') {
            this.setState({sortedUsers: this.state.sortedUsers.sort((a, b) => { return (b.totalComments + b.totalLikes) - (a.totalComments + a.totalLikes)})});
        } else if (this.sortSelector.current.value == 'newest') {
            this.setState({sortedUsers: this.state.sortedUsers.sort((a, b) => { return a.viewcount - b.viewcount})});
        }
    }

    componentDidMount() {
        let sortedUsers = this.props.allUsers.sort((a, b) => { return (b.totalComments + b.totalLikes) - (a.totalComments + a.totalLikes)});
        this.setState({sortedUsers: sortedUsers});
        logPageView(window.location.pathname);
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h3>Writers ({this.state.sortedUsers != null && this.state.sortedUsers.filter(o => (o.numstories != null && o.numstories > 0)).length})</h3>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="businerss_news">
                                    <div className="sort-by-row">
                                        <div className="sort-by-container">
                                            <div className="sort-by">Sort By:</div>
                                            <select className="sorting-dropdown" name="options" id="sort-selector" onChange={this.sortClicked} ref={this.sortSelector}>
                                                <option value="reactions">Most Reactions</option>
                                                <option value="reads">Most Reads</option>
                                                <option value="number">Number of Stories</option>
                                                <option value="newest">Newest</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                    {this.state.sortedUsers != null && this.state.sortedUsers.map((user, index) => {
                                        if (user.numstories != null && user.numstories > 0) {
                                                                                                
                                            // let userInfo = this.props.allUsers.find(o => o.uid === story.creatorID);
                                            //console.log(user);

                                            return (
                                                <div className="col-md-4 writer-col">
                                                    <div class="user-card" onClick={() => {this.props.history.push('/user/' + user.uid)}}>
                                                        <div className="user-image-container">
                                                            {user.imgUrl != null && (
                                                                <img src={user.imgUrl} alt="profile-image" className="profile-image-large"/>
                                                            )}
                                                            {user.imgUrl == null && (
                                                                <img src={darkUserIcon} alt="profile-image" className="profile-image-large"/>
                                                            )}
                                                        </div>
                                                        {user.fullname != "" && (
                                                            <div className="full-name">
                                                                {user.isAdmin && (
                                                                    <img src={adminIcon} alt="This writer is an admin." className="admin-icon"/>
                                                                )}
                                                                {user.fullname}
                                                            </div>
                                                        )}
                                                        {user.fullname == "" && (
                                                            <div className="user-name">
                                                                {user.username}
                                                            </div>
                                                        )}
                                                        <div className="center-content">
                                                            <div className="story-stats">
                                                                <div className="flex-row wrap-me">
                                                                    <div className="flex-row">
                                                                        <img src={bluePenIcon} alt="Views icon" className="eye-icon-small" />
                                                                        <div className="story-views-small-blue">{user.numstories}</div>
                                                                    </div>
                                                                    <div className="flex-row">
                                                                        <img src={eyeIcon} alt="Views icon" className="eye-icon-small" />
                                                                        <div className="story-views-small-blue">{user.viewcount}</div>
                                                                    </div>
                                                                    <div className="flex-row">
                                                                        <img src={thumbIcon} alt="Thumbs up icon" className="eye-icon-small" />
                                                                        <div className="story-views-small">{user.totalLikes}</div>
                                                                    </div>
                                                                    <div className="flex-row">
                                                                        <img src={commentsIcon} alt="Thumbs up icon" className="eye-icon-small" />
                                                                        <div className="story-views-small">{user.totalComments}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="story-stats">
                                                            <div className="flex-row">
                                                                <img src={eyeIcon} alt="View count" className="eye-icon-small" />
                                                                <div className="story-views-small-blue">{story.allStoryInformation.viewcount}</div>
                                                            </div>
                                                            <div className="flex-row">
                                                                <img src={thumbIcon} alt="Thumbs up count" className="eye-icon-small" />
                                                                <div className="story-views-small">{story.allStoryInformation.thumbsUpCount}</div>
                                                            </div>
                                                            <div className="flex-row">
                                                                <img src={commentsIcon} alt="View count" className="eye-icon-small" />
                                                                <div className="story-views-small">{story.allStoryInformation.commentsCount}</div>
                                                            </div>
                                                            <div className="flex-row">
                                                                <img src={shareSmallIcon} alt="View count" className="eye-icon-small" />
                                                                <div className="story-views-small">{story.allStoryInformation.shares.facebook + story.allStoryInformation.shares.twitter + story.allStoryInformation.shares.tumblr + story.allStoryInformation.shares.email + story.allStoryInformation.shares.link}</div>
                                                            </div>
                                                            {story.allStoryInformation.tags.map((tag, index2) => {
                                                                return (
                                                                    <div className="story-tag">{tag.toUpperCase()}</div>
                                                                )
                                                            })}
                                                        </div> */}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                        
                                </div>
                                <PromptUserToWrite/>
                                </div>

                            </div>
                            <div className="col-lg-3">
                                <SideFeedContainer/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        allUsers: state.primary.allUsers
    }
}

export default connect (mapStateToProps) (WritersPage)