import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import theKeepImage from '../../new_images/the_keep.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

import { connect } from 'react-redux';
import AnthologyAvailable from '../../components/AnthologyAvailable';
import InstagramLink from '../InstagramLink';

import nosferatuPoster from '../../new_images/reviews/nosferatu-poster.jpg';

class ReviewNosferatu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        // this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">Nosferatu Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={nosferatuPoster} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Horror</Link>
                                                        <Link>January 28, 2025</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>Nosferatu (Movie) - Robert Eggers</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                                Nosferatu is a terrifying vampire flick that sinks its fangs into the minds of its victims as well as their flesh. 
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR RATING
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            8.75 / 10
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            Fangs are far from the most terrifying aspect of a vampire. Neither are their deathly pale appearances or obsession with elaborate coffins, used for daily cat naps. The most haunting part about a vampire, in the vision of Robert Eggers, is their ability to sink their teeth into one’s mind and manipulate their psyche before even presenting their intimidating physical forms. Count Orlok (Bill Skarsgard) embodied this type of telepathic, god-like vampire caricature that has rarely made an appearance in fiction since Bram Stoker’s novel Dracula (1897) where most of the vampire content we see today originally derives. The original Nosferatu’s (1922) film’s creators initially denied any connection to the novel but after an aggressive lawsuit did admit that its familiar plot did indeed derive from Stoker’s work.</p>
                                            <p>
                                            Modern vampire media typically gets the fangs right, their paleness, avoidance of sunlight, etc. The aspect of mind control, however, seems to take more liberties from the original source material. In some representations their telepathy seems to be missing completely. In those cases their mental influence is replaced by their charisma, wealth, and attractive physical appearance.  In the iterations where the mind control exists, it’s usually limited in some way and relies on the victim of the telepathy being in close proximity to the vampire. Count Orlok, on the other hand, is able to extend his manipulative tendrils over a thousand miles away to develop his influence on a seaside european town before he even steps foot on its shores. The film heavily leaned into the telepathic abilities of a vampire, as the novel did, allowing the tension throughout the movie to increase dramatically. Not only did you have to worry about a young solicitor's encounters with the physical demon, you also had to witness his bride lose her sanity to the intangible version of the creature back home. The film’s reliance on mind control also allowed unexpected characters to take on the role of the most disturbing member of the cast depending on who was under the influence of Orlok at that moment.  
                                            </p>
                                            <p>
                                            Mind control and mental manipulation aside, the film also excelled at making the representation of Orlok himself truly terrifying. Though there are moments where you get to inspect the character up close which make him less intimidating, there are plenty of moments that heighten the viewer’s fear of the character. The most fear inducing representations of the character are often shown using shadow. You’re never truly able to trust a bit of darkness because you know the count could be lurking, whether in the form of a vision or his unsettling physical form. You can also see Orlok’s impact on the town increase as the darkness spreads and shadows continue to fall.                                           
                                            </p>
                                            <p>
                                            I would wholeheartedly recommend the film to anyone that appreciates the source novel, is a horror fan in general, or enjoys causing themselves emotional distress. This movie will not disappoint you. As always, let us know what you thought of the film in the comments below.                                           
                                            </p>

                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="nosferatu" currentUser={this.props.currentUser}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <NewsLetter/>
                            <AnthologyAvailable/>
                            <InstagramLink/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
        </Fragment>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts
    }
}

export default connect (mapStateToProps) (ReviewNosferatu)