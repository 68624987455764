import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Link} from "react-router-dom";

import darkUserIcon from '../new_images/icons/dark-user-icon.png';

import './LikesModal.css';

export default class LikesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} className="likes-modal">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Liked By
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div clasName="liked-profiles-list">
                        {this.props.likes.map((id, i) => {
                            let userInfo = this.props.allUsers.find(o => o.uid === id);

                            return (
                                <div className="liked-profile">
                                    {userInfo.imgUrl != null && (
                                            <img src={userInfo.imgUrl} alt="User image" className="story-page-author-image"/>
                                    )}

                                    {userInfo.imgUrl == null && (
                                        <img src={darkUserIcon} alt="User image" className="story-page-author-image"/>
                                    )}

                                    <div className="liked-profile-info">
                                        <div className="flex-row">
                                            <div className="liked-profile-names">
                                                {userInfo.fullname != "" && (
                                                    <div className="liked-profile-fullname">{userInfo.fullname}</div>
                                                )}

                                                <div className="liked-profile-username">@{userInfo.username}</div>
                                            </div>

                                            <Link to={`/user/${id}`}><Button variant="primary" className="view-profile-button">View</Button></Link>

                                        </div>

                                        <div className="liked-profile-description">
                                            {userInfo.description.length != 0 && (
                                                <>{userInfo.description}</>
                                            )}
                                            {userInfo.description.length == 0 && (
                                                <>No description to display!</>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}