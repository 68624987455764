import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import './CreateAccountModal.css';

export default class AddMoreInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Add More Information
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-content">
                        <div className="short-description">
                            Add more information to your profile page to let users know who you are!
                        </div>
                    </div>
                    <div className="center-content">
                        <Button variant="primary" size="lg" className="pad-right-10" onClick={() => {this.props.closeModal()}}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}