import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import './CreateAccountModal.css';

export default class ExitPageModal extends React.Component {
    constructor(props) {
        super(props);

        this.errorMessage = React.createRef();
        this.emailRef = React.createRef();

        this.state = {  
            addedToList: false      
        };
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    addUserEmail() {
        if (this.validateEmail(this.emailRef.current.value)) {
            let db = this.props.firebase.firestore();
            db.collection("email-list").add({
                email: this.emailRef.current.value
            })
            this.setState({addedToList: true})
        } else {
            this.errorMessage.current.style.display = "block";
        }
    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Leaving so Soon?
                        </h3>
                    </div>
                </Modal.Header>
                {!this.state.addedToList && (
                    <Modal.Body>
                        <div className="center-content">
                            <div className="short-description">
                                Join our email list to get all the latest updates!
                            </div>
                        </div>
                        <div className="center-content">
                            <Form.Control type="email" placeholder="Enter email" className="email-list-popup-form" ref={this.emailRef}/>
                        </div>
                        <div className="center-content">
                            <div className="error-message" ref={this.errorMessage}>
                                Email is not formatted correctly!
                            </div>
                        </div>
                        <div className="center-content">
                            <Button variant="primary" size="lg" className="pad-right-10" onClick={() => {this.addUserEmail()}}>
                                Receive Updates
                            </Button>
                        </div>
                    </Modal.Body>
                )}
                {this.state.addedToList && (
                    <Modal.Body>
                        <div className="center-content">
                            <div className="short-description">
                                Thanks, you've been added! We'll be in touch. You can also create an account below.
                            </div>
                        </div>
                        <div className="center-content">
                            <Button variant="primary" size="lg" className="pad-right-10" onClick={() => {this.props.createAccount()}}>
                                Create Account
                            </Button>
                        </div>
                    </Modal.Body>
                )}
            </Modal>
        )
    }

}