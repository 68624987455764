import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../components/NewsLetter";
import InstagramLink from './InstagramLink';

import CategoriesWidget from '../components/CategoriesWidget';

import './CategoryPages.css';

import '../components/primary-styles.css';

import theKeepImage from '../new_images/the_keep.jpg';
import theTimeMachineImage from '../new_images/time_machine.jpg';
import invisibleManImage from '../new_images/invisible_man.jpg';
import theTombImage from '../new_images/the_tomb.jpg';
import unsolicitedImage from '../new_images/unsolicited.jpg';
import barbarianPoster from '../new_images/reviews/barbarian-movie-poster.webp';
import theEyesPoster from '../new_images/reviews/the-eyes-are-the-best.jpg';
import nosferatuPoster from '../new_images/reviews/nosferatu-poster.jpg';
import theRoadPoster from '../new_images/reviews/the-road-poster.jpg';
import theOutsiderPoster from '../new_images/reviews/the-outsider.jpg';

import FillOutSurvey from '../components/FillOutSurvey';
import AnthologyAvailable from '../components/AnthologyAvailable';

export default class ReviewsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        // this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h1>Reviews</h1>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="businerss_news">
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/theoutsider"><img src={theOutsiderPoster} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Thriller</Link>
                                                        <Link to="/">March 21, 2025</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/theoutsider">The Outsider (Book) - Stephen King</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/theroad"><img src={theRoadPoster} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Science Fiction</Link>
                                                        <Link to="/">February 28, 2025</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/theroad">The Road (Book) - Cormac McCarthy</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/nosferatu"><img src={nosferatuPoster} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Horror</Link>
                                                        <Link to="/">January 28, 2025</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/barbarian">Nosferatu (Movie) - Robert Eggers</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/theeyes"><img src={theEyesPoster} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Horror</Link>
                                                        <Link to="/">December 28, 2024</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/theeyes">The Eyes Are The Best Part (Novel) - Monika Kim</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/barbarian"><img src={barbarianPoster} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Horror</Link>
                                                        <Link to="/">November 20, 2024</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/barbarian">Barbarian (Movie) - Zach Cregger</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/the-tomb"><img src={theTombImage} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Supernatural</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/the-tomb">The Tomb - F. Paul Wilson</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                         <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/the-invisible-man"><img src={invisibleManImage} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Science Fiction</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/the-invisible-man">The Invisible Man - H.G. Wells</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/the-time-machine"><img src={theTimeMachineImage} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Science Fiction</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/the-time-machine">The Time Machine - H.G. Wells</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/reviews/unsolicited"><img src={unsolicitedImage} alt="book image" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Science Fiction</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/reviews/unsolicited">Unsolicited - Julie Kaewert</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <NewsLetter/>
                                <AnthologyAvailable/>
                                <InstagramLink/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-70"/>
            </Fragment>
        );
    }
};