import React, {Fragment, useEffect, useRef, useState} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from 'react-router-dom';

import '../PromptsListPage.css';
import { css } from '@emotion/css';
import PromptUserToWrite from '../PromptUserToWrite';

import '../NewsletterPage.css';
import SideFeedContainer from '../SideFeedContainer';
import {connect} from 'react-redux';

class CompetitionsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortedcompetitions: null
        };

        this.sortSelector = React.createRef();

        this.sortClicked = this.sortClicked.bind(this);
    }

    sortClicked () {
        //console.log(this.sortSelector.current.value);
        //(this.state.sortedcompetitions);
        if (this.sortSelector.current.value == 'date') {
            this.setState({sortedcompetitions: this.state.sortedcompetitions.sort((a, b) => { return b.timestamp - a.timestamp;})})
        } else if (this.sortSelector.current.value == 'popularity') {
            this.setState({sortedcompetitions: this.state.sortedcompetitions.sort((a, b) => { return b.storyIds.length - a.storyIds.length;})})
        }
    }

    componentDidMount() {
        this.setState({sortedcompetitions: this.props.allCompetitions.sort((a, b) => { return b.timestamp - a.timestamp;})});
    }

    render () {
        return (
            <Fragment>
                {!this.props.justContent && <BreadCrumb title="Business"/>}
                <div className={!this.props.justContent ? "archives padding-top-50": "archives"}>
                    <div className="container">
                        <h3>Competitions ({this.props.allCompetitions.length})</h3>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className={!this.props.justContent ? "col-lg-9" : ""}>
                                <div className="sort-by-row">
                                    <div className="sort-by-container">
                                        <div className="sort-by">Sort By:</div>
                                        <select className="sorting-dropdown" name="options" id="sort-selector" onChange={this.sortClicked} ref={this.sortSelector}>
                                            <option value="date">Recently Added</option>
                                            <option value="popularity">Most Popular</option>
                                        </select>
                                    </div>
                                </div>
    
                                <div className="wrapper-40">
                                    {this.state.sortedcompetitions != null && this.state.sortedcompetitions.map((prompt, index) => {
                                        //console.log(this.state.sortedcompetitions)
                                        return (
                                            <Link className="prompt-container" to={`/competitions/${prompt.id}`}>
                                                <div className="prompt-title">
                                                    {prompt.title}
                                                </div>
                                                <div className="prompt-description">
                                                    {prompt.description}
                                                </div>
                                                {prompt.status === 'live' && (
                                                    <div className={css`color: #79AAF7;`}>
                                                        {prompt.storyIds.length} Submission(s) - Due {new Date(prompt.due).toLocaleDateString("en-US")}
                                                    </div>
                                                )}
                                                {prompt.status === 'complete' && (
                                                    <div className={css`color: #79AAF7;`}>
                                                        {prompt.storyIds.length} Submission(s) - CLOSED
                                                    </div>
                                                )}
                                            </Link>
                                        )
                                    })}
                                    <PromptUserToWrite/>
                                </div>
    
                            </div>
                            {!this.props.justContent && (
                                <div className="col-lg-3">
                                    <SideFeedContainer showCategories={false}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allCompetitions: state.primary.allCompetitions
    }
}

export default connect (mapStateToProps) (CompetitionsPage)