import React, {Fragment} from 'react';

import thumbUpOutline from '../new_images/thumbs/thumbs_up_outline.png';
import thumbDownOutline from '../new_images/thumbs/thumbs_down_outline.png';

import './SingleReviewPage.css';

import CommentsSection from './CommentsSection';

import './ReviewPages/CommentSectionReviews.css';
import { getDBRef } from '../FirebaseActions';

export default class CommentSectionStory extends React.Component {

    constructor(props) {
        super(props);
        this.db = getDBRef();
        this.state = {   
            showModal: false,
            thumbUp: false,
            thumDown: false
        };
        this.thumbUpClicked = this.thumbUpClicked.bind(this);
        this.thumbDownClicked = this.thumbDownClicked.bind(this);
        this.wasSuccess = this.wasSuccess.bind(this);
    }

    wasSuccess() {
        this.setState({wasSuccess: true});
    }

    closeModal() {
        this.setState({showModal: false});
    }

    sendReaction(reaction) {
        let updatedReactions = {};
        if (this.props.currentUser.reactions != null) {
            updatedReactions = this.props.currentUser.reactions;
        }
        updatedReactions[this.props.story.id] = reaction;

        this.db.collection("users").doc(this.props.currentUser.uid).update({
            reactions: updatedReactions
        })

        if (reaction) {

            let thumbsUp = this.props.story.allStoryInformation.thumbsUp;
            thumbsUp.push(this.props.currentUser.uid);

            this.db.collection("all-stories").doc(this.props.story.id).update({
                "allStoryInformation.thumbsUp": thumbsUp
            })

            let newNotifications = this.props.authorInfo.notifications;
            newNotifications.hasActive = true;
            newNotifications.list.unshift({
                message: `${this.props.currentUser.username} has liked your story '${this.props.story.allStoryInformation.title}'!`,
                read: false,
                storyId: this.props.story.id
            })

            this.db.collection("users").doc(this.props.authorInfo.uid).update({
                totalLikes: this.props.authorInfo.totalLikes + 1,
                notifications: newNotifications
            })
        } else {
            this.db.collection("all-stories").doc(this.props.story.id).update({
                "allStoryInformation.thumbsDownCount": (this.props.story.allStoryInformation.thumbsDownCount + 1)
            })

            // this.db.collection("users").doc(this.props.userInformation.uid).collection("stories").doc(this.props.story.id).update({
            //     "allStoryInformation.thumbsDownCount": (this.props.story.allStoryInformation.thumbsDownCount + 1)
            // })
        }
    }

    thumbUpClicked() {
        if (!this.state.thumbUp && !this.state.thumbDown) {
            if (this.props.currentUser != null) {
                document.getElementById("thumb-up").classList.add("like-dislike-clicked");
                this.setState({thumbUp: true});
                this.sendReaction(true);
            } else {
                this.props.showAccountModal();
            }
        }
    }

    thumbDownClicked() {
        if (!this.state.thumbUp && !this.state.thumbDown) {
            if (this.props.currentUser != null) {
                document.getElementById("thumb-down").classList.add("like-dislike-clicked");
                this.setState({thumbDown: true});
                this.sendReaction(false);
            } else {
                this.props.showAccountModal();
            }
        }
    }

    checkReactions() {
        //console.log(this.props.story);
        //console.log(this.props.currentUser);
        if (this.props.currentUser.reactions != null) {
            for (let id of Object.keys(this.props.currentUser.reactions)) {
                let value = this.props.currentUser.reactions[id];
                //console.log(id, value);
                if (id == this.props.story.id) {
                    if (value == true) {
                        document.getElementById("thumb-up").classList.add("like-dislike-clicked");
                        this.setState(() => {return {thumbUp: true}});
                    } else {
                        document.getElementById("thumb-down").classList.add("like-dislike-clicked");
                        this.setState(() => {return {thumbDown: true}});
                    }
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.currentUser != null) {
            this.checkReactions();
        }
        //console.log(this.state.thumbDown);
    }

    render() {
        return ( 
            <div className="customer-review-section">
                <h2>What did you think of this story?</h2>
                <div className="thumbs-container">
                    <div className="like-dislike" id="thumb-up">
                        <img src={thumbUpOutline} onClick={this.thumbUpClicked} />
                    </div>
                    <div className="like-dislike" id="thumb-down">
                        <img src={thumbDownOutline} onClick={this.thumbDownClicked}/>
                    </div>
                </div>
                <CommentsSection showAccountModal={this.props.showAccountModal} authorInfo={this.props.authorInfo} reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} wasSuccess={this.wasSuccess} type="story" currentUser={this.props.currentUser} story={this.props.story}/>
            </div>
        )
    }
}