import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import invisibleManImage from '../../new_images/invisible_man.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

export default class ReviewTheInvisibleMan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">The Invisible Man Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link to="/"><img src={invisibleManImage} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Supernatural</Link>
                                                        <Link to="/">December 3, 2020</Link>
                                                    </div>
                                                    <h4>
                                                        <Link to="/">The Invisible Man - H.G. Wells</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                                F. Paul Wilson brings together the stories of father and daughter, the struggle of living in Nazi occupation, and fear of the supernatural together to create a riveting and haunting story.
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        USERS VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>The Keep - Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                                She stood across the room, observing conversations from a distance. She felt the joy and belonging everyone else could feel but she was still removed. As if she transcended this place in time and in spirit. It was as if she were floating among the stars, giving mortals just a glimpse of what a higher being felt like. Drinking nectar and dining in Zeus's court. And then just as quickly as it came, the magic had fled. Joel from accounting had walked over and the goddess had flown back into the safety above. The mortal woman, however, remained. That magical gaze had left, what was once full of curiosity and hope was now filled with disappointment. She could tell the coming conversation wouldn't contain much depth. Joel lifted his glass and I could see him gesturing towards it as if to ask, "Are you out?" She gave a half smile and a slight nod that hit me very deep.
                                            </p>
                                            <p>
                                                I felt eyes upon me wondering why I looked on for so long in a particular direction. I turned around however and realized it was only a few seconds. It felt like an eternity. I had become a prisoner to that gaze and couldn't escape until it was interrupted. I turned back to the bar and took another drink. Attempting to forget what I had seen, how powerful of a trance I was put into but I simply couldn't. I began to experience something I hadn't felt in a while. The preemptive knowledge that this was going to be on my mind for a long time. Like it would find a little hole to bury itself into the back of my head to always be a constant reminder. Even if I forgot it for a while it would somehow always find a way back. I began to think maybe if I could forget it fast enough I could escape this detrimental fate. As an attempt to do this I took another drink, and another. I waved the bartender over and he began to pour me another. My thoughts started to become less concentrated and my body felt heavier on the stool. 
                                            </p>
                                            <p>
                                                That seed in my head still was growing out its roots but I could tell there would be stunted growth. Maybe not enough to make me completely forget, but enough to make the experience less enchanting. Make her less mesmerizing, so maybe, just maybe I would wake up and feel as if she was just another average person, observing the world in an average sort of way. With no features of a transcendent being, gracing us humans at a particular point in time with her presence. I found myself falling down this sort of trap of thoughts again. The realization came that it would be harder than I thought to erase these memories. 
                                            </p>
                                            <p>
                                                I know what they would say if they found out again. “Oh how troubled he is. He can’t rely on the normal methods of dealing with stress and heartbreak.” I was better now though, I learned my lesson and experienced the full stages of recovery. This time it was different, I wasn’t going back because it was an easy alternative. I simply knew that this was the only way to forget. I didn’t want to wake up and be obsessed, never being able to get her off of my mind. 
                                            </p>
                                            <p>
                                                I knew he had to be here somewhere. It was less than a year ago that Jade seemed to be in constant supply. Any time day or night. Not that it was an issue considering I always seemed to buy in bulk. I walked around the bar and observed the other side of the room. I only hoped that his recent promotion wouldn’t affect his commitment to extracurricular activities. There he was, drinking alone as surprising as it was. I approached without hesitation.
                                            </p>
                                            <p>
                                                “Hi there Thomas, it’s been a while. How are you liking the party?” Jade said. His eyes seemed to analyze me. “You look like you’ve seen a ghost. Do you need some water?”
                                            </p>
                                            <p>
                                                “Yes, some water. And some assistance, like in the old days.”
                                            </p>
                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="invisible_man" currentUser={this.props.currentUser} firebase={this.props.firebase}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <CategoriesWidget/>
                            <NewsLetter firebase={this.props.firebase}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
            <ThreeRecentStories allStories={this.props.allStories} allUsers={this.props.allUsers} history={this.props.history}/>
        </Fragment>
        )
    }
};