import React, {Fragment, lazy} from 'react';
import PostGallery from "../../components/PostGallery";
import HomeCarousel from '../../components/HomeCarousel';
import FeatureNews from "../../components/FeatureNews";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";
import CategoriesWidget from "../../components/CategoriesWidget";
import InstagramLink from '../InstagramLink';

import CategoriesCarousel from '../../components/CategoriesCarousel';
import { PayPalDonationButton } from '../../components/PayPalDonationButton';

import FillOutSurvey from '../../components/FillOutSurvey';

import WriteStoryButton from '../WriteStoryButton';

import AllStories from '../AllStories';
import TwitterFeed from '../../components/TwitterFeed';
import CompetitionsPage from '../competitions/CompetitionsPage';
import StoreCarousel from '../../components/StoreCarousel';

export default class HomeDarkPage extends React.Component {

    render() {
        return (
            <Fragment>
                <br/>
                {/* <PostGallery className="primay_bg dark-v"/> */}
                <HomeCarousel/>
                {/* <FeatureNews className="dark-v"/> */}
                {/* <CategoriesCarousel className="dark-v"/> */}
                <StoreCarousel className="dark-v"/>
                <div className="entertrainments dark-v">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                {/* <AllStories/> */}
                                <CompetitionsPage justContent={true}/>
                            </div>
                            <div className="col-lg-3">
                                <div className="row">
                                    {/* <div className="col-lg-12">
                                        <CategoriesWidget/>
                                    </div> */}
                                    <div className="col-lg-12">
                                        <div className="space-20"/>
                                        <WriteStoryButton/>
                                        <PayPalDonationButton/>
                                        <NewsLetter/>
                                        <InstagramLink/>
                                        <TwitterFeed/>
                                        <FillOutSurvey/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-70"/>
            </Fragment>
        );
    }
};