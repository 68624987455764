import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import theKeepImage from '../../new_images/the_keep.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

import { connect } from 'react-redux';
import AnthologyAvailable from '../../components/AnthologyAvailable';
import InstagramLink from '../InstagramLink';

import theRoadPoster from '../../new_images/reviews/the-road-poster.jpg';

class ReviewTheRoad extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        // this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">The Road Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={theRoadPoster} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Science Fiction</Link>
                                                        <Link>February 28, 2025</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>The Road (Book) - Cormac McCarthy</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                                A post Apocalyptic world. Communes and factions battling for survival. A man and his son in the middle of it all. Classic.  
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR RATING
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            7.75 / 10
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            A post Apocalyptic world. Communes and factions battling for survival. A man and his son in the middle of it all. Classic. 
                                            </p>                                            
                                            <p>
                                            Cormac Mccarthy’s The Road won him the Pulitzer Prize. That in itself makes this book a must read. I will admit, he is a hard author to jump into, since his style of writing is hyper-descriptive, as well as a minimal representation of characters when dialogue is present. This type of writing can be a bit disorienting, and a reader must be able to focus hard in order to comprehend his writing and figure out who is talking or what is going on. This type of writing is successful though in regard to creating a feeling of emptiness, which this book needs in order to construct not only a plot rich with horror and despair, but also to characterize the characters in the sense of mental stability and comprehension of their situation.                                            
                                            </p>
                                            <p>
                                            The story begins, and is constant in the sense that the father and son are surviving in a post apocalyptic world. What happened isn’t necessarily clear (At least to me), but what we do know is that the father and son are following a road south to a place that he deems safe, or at least that was what he told the boy. There is a pattern to this book that is the reason I couldn’t give the book an 8 or higher. This pattern involves the pair going off the road to scavenge for food and tools. The father then finds a unique situation where they could possibly get tools and/or food. The son is scared and warns him not to go in or explore. The father brushes off his son’s apprehensions of the situation and goes in. They end up finding something grotesque or inherently sobering for him and terrifying for the son and they get back on the road. They do have a few situations where they get lucky and get copious amounts of food or tools, but these situations are positive situations that are draped in dread for what is to come once their luck runs out. What is done well with this story is the constant morality and strength mentally to survive that are constantly reminding the reader in order to bludgeon them with the fact there will be an end to this situation, the only questions are how and when. The man is always thinking of the boy’s survival, and battles with not only his own morality and if he should just give it all up, but also the boy’s timeline and how to keep him safe. There are times where he asks the boy to get ready to kill himself, and feels terrible about it immediately. Not that he is regretful that he put the boy in that situation, but rather that he upset the boy. This is just one example of the grotesque nature of the world that Cormac Mccarthy has created.                                             
                                            </p>
                                            <p>
                                            This book is not for the faint of heart, and is a sobering story that makes us think about how stable the human mind really is.                                            
                                            </p>

                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="theroad" currentUser={this.props.currentUser}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <NewsLetter/>
                            <AnthologyAvailable/>
                            <InstagramLink/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
        </Fragment>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts
    }
}

export default connect (mapStateToProps) (ReviewTheRoad)