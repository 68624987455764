import React, {useState} from 'react';
import {Link} from "react-router-dom";
import FooterCopyright from "../FooterCopyright";
import FontAwesome from "../uiStyle/FontAwesome";

const FooterArea = ({className}) => {
    const [email, setEmail] = useState('');
    const submitHandler = e => {
        e.preventDefault();
        setEmail('')
    };
    return (
        <div className={`footer footer_area1 ${className ? className : ''}`}>
            <FooterCopyright/>
        </div>
    );
};

export default FooterArea;