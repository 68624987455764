import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';

import theOutsiderPoster from '../../new_images/reviews/the-outsider.jpg';
import { connect } from 'react-redux';
import AnthologyAvailable from '../../components/AnthologyAvailable';
import InstagramLink from '../InstagramLink';

class ReviewTheEyes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        // this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">The Outsider Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={theOutsiderPoster} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Horror</Link>
                                                        <Link>March 21, 2025</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>The Outsider (Novel) - Stephen King</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                                The Outsider is an effective mystery tale with layered characters while also delivering the proper scares during the straight horror scenes.
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR RATING
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            7.75 / 10
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            The plot of many modern-day supernatural novels throws inconceivable scenarios at the central characters and expects them to accept their unfamiliar surroundings immediately. Which, in many cases, they do. This seems unrealistic oftentimes as the reader considering that we reassure ourselves the supernatural wouldn’t be so easily believed. Stephen King crafts characters in The Outsider that are so stuck in their ways they can’t conceive of a paranormal world even when it is slapping them in the face. The failure of the central characters to acknowledge the existence of the otherworldly ultimately causes plenty of unnecessary heartache throughout the book. I quite liked this twist on supernatural belief and found it to be more true to human nature.                           </p>
                                            <p>
                                            The first half of King’s work was reminiscent of the famous Sherlock Holmes quote: “When you have eliminated the impossible, whatever remains, however improbable, must be the truth.” It was very much the case that Detective Anderson, among others, was staring at a statistically improbable truth in the eye and refused to believe it because it would make their belief in a natural and predictable world impossible. Ultimately this led to one of the most emotional portions of the book for me where an innocent family man suffers the consequences of others' failure to believe in the improbable. This seemed to give light to one of the core themes in the piece that evil will often triumph over justice when it is ignored. 
                                            </p>
                                            <p>
                                            Evidence of the supernatural was in and of itself a spine-tingling portion of the book but the description of the central antagonist was equally terrifying. I fear that I won’t be able to enter a dark room again for some time without seeing the red straw eyes of King’s embodiment of evil staring back at me. I also particularly enjoyed scenes where a character would observe subtle differences in a person's behavior and body language that seemed to indicate the person may be under the influence of the antagonist. It seemed to be as if the character’s belief in a natural world was slowly crumbling as they realized the person wasn’t entirely human.                                         
                                            </p>
                                            <p>
                                            Ultimately I found the book to be an effective mystery tale while also delivering the proper scares during the straight horror scenes. The characters were layered and interesting. There were some more stereotypical tropes but ultimately I found the plot to be uniquely creative. It was a bit slower paced than I’d like it to be at the beginning which will knock it down a couple points but nothing detrimental to my overall enjoyment of the story.                                   
                                            </p>

                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="theoutsider" currentUser={this.props.currentUser}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <NewsLetter/>
                            <AnthologyAvailable/>
                            <InstagramLink/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
        </Fragment>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts
    }
}

export default connect (mapStateToProps) (ReviewTheEyes)