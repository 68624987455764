import React from 'react';

import '../components/primary-styles.css';

import tagIcon from '../new_images/pricetag-icon.png';
import eyeIcon from '../new_images/eye-icon.png';
import thumbIcon from '../new_images/thumbs_up.png';
import darkUserIcon from '../new_images/icons/dark-user-icon.png';
import commentsIcon from '../new_images/icons/comments-icon.png';
import shareSmallIcon from '../new_images/icons/share-small-icon.png';

import StoryPreview from '../containers/StoryPreview';

export default class ThreeRecentStories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {       
            showCreateAccount: true,
            showSuccess: false,
            randomStories: null
        };
    }

    componentDidMount() {
        let randOne = this.props.allStories[Math.floor(Math.random() * this.props.allStories.length)];
        let randTwo = this.props.allStories[Math.floor(Math.random() * this.props.allStories.length)];
        let randThree = this.props.allStories[Math.floor(Math.random() * this.props.allStories.length)];
        let randFour = this.props.allStories[Math.floor(Math.random() * this.props.allStories.length)];
        
        let randomStories = [];
        randomStories.push(randOne, randTwo, randThree, randFour);
        this.setState({randomStories: randomStories});
    }

    render() {

        let colWidth = 3;

        if (window.screen.width < 1000) {
            colWidth = 4;
        }

        return ( 
            <div className={`${'primay_bg'} padding6030`}>
            <div className="container no-padding">
                <div className="row">
                    <div className="col-12">
                        <div className="heading">
                            <h2 className="widget-title">Recent Short Stories</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                {this.state.randomStories != null && this.state.randomStories.map((story, index) => {
                    if (story.hidden == null && story.allStoryInformation.competitionId == null) {
                                                                            
                        let userInfo = this.props.allUsers.find(o => o.uid === story.creatorID);

                        return (
                            <StoryPreview story={story} userInfo={userInfo} colWidth={colWidth}/>
                        )
                      }
                    })}
                </div>
            </div>
            </div>
        )
    }
}