import React, {Fragment, useEffect, useRef, useState} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import NewsLetter from "../components/NewsLetter";
import CategoriesWidget from '../components/CategoriesWidget';
import InstagramLink from './InstagramLink';

import sciFiBannerBig from '../new_images/science_fiction_big_banner.jpg';
import supernaturalBannerBig from '../new_images/supernatural_big_banner.jpg';
import fantasyBigBanner from '../new_images/fantasy_big_banner.jpg';
import horrorBannerBig from '../new_images/horror_big_banner.jpg';
import thrillerBannerBig from '../new_images/thriller_big_banner.jpg';
import histFicBigImage from '../new_images/histfic-big-image.jpg';

import FillOutSurvey from '../components/FillOutSurvey';

import './CategoryPages.css';

import '../components/primary-styles.css';

import PromptUserToWrite from './PromptUserToWrite';
import StoryPreview from './StoryPreview';
import { PayPalDonationButton } from '../components/PayPalDonationButton';
import TwitterFeed from '../components/TwitterFeed';
import SideFeedContainer from './SideFeedContainer';
import { connect } from 'react-redux';
import {logPageView} from '../FirebaseActions';

const CategoryPage = (props) => {
    const [sortedStories, setSortedStories] = useState(null);
    const [categoryAbbr, setCategoryAbbr] = useState((window.location.pathname).slice(10));
    const [categoryName, setCategoryName] = useState("");

    const sortSelector = useRef();

    const storyClicked = (story) => {
        props.history.push('/story/' + story.id);
    }

    const sortClicked = (e) => {
        console.log(e.target.value)
        console.log(sortSelector.current.value);
        if (sortSelector.current.value == 'date') {
            setSortedStories(sortedStories.sort((a, b) => { return b.timestamp - a.timestamp;}));
        } else if (sortSelector.current.value == 'mostreads') {
            setSortedStories(sortedStories.sort((a, b) => { return (b.allStoryInformation.viewcount) - (a.allStoryInformation.viewcount)}));
        } else if (sortSelector.current.value == 'mostreact') {
            setSortedStories(sortedStories.sort((a, b) => { return (b.allStoryInformation.commentsCount + b.allStoryInformation.thumbsUp.length) - (a.allStoryInformation.commentsCount + a.allStoryInformation.thumbsUp.length)}));
        } else if (sortSelector.current.value == 'longest') {
            setSortedStories(sortedStories.sort((a, b) => { return b.allStoryInformation.storyText.split(' ').length - a.allStoryInformation.storyText.split(' ').length}));
        } else if (sortSelector.current.value == 'shortest') {
            setSortedStories(sortedStories.sort((a, b) => { return a.allStoryInformation.storyText.split(' ').length - b.allStoryInformation.storyText.split(' ').length}));
        } else if (sortSelector.current.value == 'poplate') {
            let sortedByDate = sortedStories.sort((a, b) => { return b.timestamp - a.timestamp;});
            let recentPortion = Math.round(sortedByDate.length / 3);
            let firstPart = sortedByDate.slice(0, recentPortion);
            firstPart.sort((a, b) => { return (b.allStoryInformation.viewcount + b.allStoryInformation.thumbsUp.length) - (a.allStoryInformation.viewcount + a.allStoryInformation.thumbsUp.length)});
            let secondPart = sortedByDate.slice(-(sortedByDate.length - recentPortion));
            setSortedStories(firstPart.concat(secondPart));
        }
    }

    const setCategoryInfo = () => {
        logPageView(window.location.pathname);
        //console.log((window.location.pathname).slice(10));

        let categoryAbbr = (window.location.pathname).slice(10);
        let categoryName = "";

        if (categoryAbbr == 'scifi') { categoryName = 'Science Fiction'}
        else if (categoryAbbr == 'supernatural') { categoryName = 'Supernatural'}
        else if (categoryAbbr == 'horror') { categoryName = 'Horror'}
        else if (categoryAbbr == 'fantasy') { categoryName = 'Fantasy'}
        else if (categoryAbbr == 'thriller') { categoryName = 'Thriller'}
        else if (categoryAbbr == 'histfic') { categoryName = 'Historical Fiction'}

        sortSelector.current.value = "date";

        setCategoryName(categoryName);
        setSortedStories(props.allStories.filter(story => story.allStoryInformation.tags.includes(categoryName)).sort((a, b) => { return b.timestamp - a.timestamp;}));
    }

    useEffect(() => {
        console.log(props);
        console.log(window.location.pathname);
        if (categoryAbbr != (window.location.pathname).slice(10)) {
            setCategoryAbbr((window.location.pathname).slice(10));
            setSortedStories(null);
            setCategoryInfo();
        }
    }, [window.location.pathname]);

    useEffect(() => {
        console.log(props);
        console.log(props.history.location.pathname)
        setCategoryInfo();
    }, [props.pathname])

        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <div className="row row-eq-height">
                            <div className="col-lg-9">
                                <div className="businerss_news">
                                    <div className="row">
                                        <div className="col-12 align-self-center no-padding">
                                            <div className="category-title-container">
                                                {categoryName == 'Science Fiction' && (<img src={sciFiBannerBig} alt="category-title" />)}
                                                {categoryName == 'Supernatural' && (<img src={supernaturalBannerBig} alt="category-title" />)}
                                                {categoryName == 'Fantasy' && (<img src={fantasyBigBanner} alt="category-title" />)}
                                                {categoryName == 'Horror' && (<img src={horrorBannerBig} alt="category-title" />)}
                                                {categoryName == 'Thriller' && (<img src={thrillerBannerBig} alt="category-title" />)}
                                                {categoryName == 'Historical Fiction' && (<img src={histFicBigImage} alt="category-title" />)}
                                                <div className="category-title"><h2>{categoryName}</h2></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sort-by-row">
                                        <div className="sort-by-container">
                                            <div className="sort-by">Sort By:</div>
                                            <select className="sorting-dropdown" name="options" id="sort-selector" onChange={sortClicked} ref={sortSelector}>
                                                <option value="date">Date Added</option>
                                                <option value="poplate">Popular Lately</option>
                                                <option value="mostreads">Most Reads</option>
                                                <option value="mostreact">Most Reactions</option>
                                                <option value="longest">Longest</option>
                                                <option value="shortest">Shortest</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {sortedStories != null && sortedStories.map((story, index) => {
                                                
                                            let userInfo = props.allUsers.find(o => o.uid === story.creatorID);

                                            if (story.hidden == null && story.allStoryInformation.competitionId == null) {
                                                return (
                                                    <StoryPreview story={story} userInfo={userInfo} colWidth={4}/>
                                                )
                                            }
                                        })}
                                    </div>
                                    <PromptUserToWrite/>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <SideFeedContainer/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
}

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers
    }
}

export default connect (mapStateToProps) (CategoryPage)