import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import darkUserIcon from '../new_images/icons/dark-user-icon.png';

import './CreateAccountModal.css';
import { getStorageRef, regrabUserInfoFB } from '../FirebaseActions';

export default class OpenImageModal extends React.Component {
    constructor(props) {
        super(props);

        this.imageSuccess = React.createRef();

        this.state = {        
        };
        this.imageUploaded = this.imageUploaded.bind(this);
    }

    imageUploaded(event) {
        let files = event.target.files;
        //console.log(files[0]);
        let newImage = files[0];

        // Create a root reference
        var storageRef = getStorageRef();

        // Create a reference to 'images/mountains.jpg'
        var newImageRef = storageRef.child('user-images/' + this.props.currentUser.uid + '/user_img.png');

        // 'file' comes from the Blob or File API
        newImageRef.put(newImage).then((snapshot) => {
            //console.log('Uploaded a blob or file!');
            this.imageSuccess.current.style.display="block";
            regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
        });
    }

    componentDidMount() {

    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Change Image
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-content">
                        {this.props.currentUser.imgUrl != null && (
                            <img src={this.props.currentUser.imgUrl} alt="profile-image" className="profile-image-large"/>
                        )}
                        {this.props.currentUser.imgUrl == null && (
                            <img src={darkUserIcon} alt="profile-image" className="profile-image-large"/>
                        )}
                    </div>
                    <div className="center-content">
                        <Form className="new-image-input">
                            <Form.Group>
                                <Form.File id="exampleFormControlFile1" label="Input your file here" onChange={this.imageUploaded} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="center-content">
                        <div className="image-uploaded-success" ref={this.imageSuccess}>
                            Your new image was successfully updated!
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}