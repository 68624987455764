import { createStore } from 'redux'

const initialState = {
  posts: [],
  loginModal: {
    open: false
  },
  isLoginModalOpen: false
}

const reducer = (state = initialState, action) => {
  if (action.type === 'ADD_POST') {
    return Object.assign({}, state, {
      posts: state.posts.concat(action.payload)
    })
  } else if (action.type === 'LOGIN_MODAL') {
    return Object.assign({}, state, {
        isLoginModalOpen: action.payload
    })
  }

  return state
}

const store = createStore(reducer)

export default store;