import React from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import AnthologyCoverV2 from '../new_images/publications/AnthologyCoverV2.jpg';

export default class AnthologyAvailable extends React.Component {

    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.state = {        
            subscribed: false
        };
        this.submitClicked = this.submitClicked.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    submitClicked() {
        //console.log(this.emailRef.current.value);
        
    }

    closeModal() {
        this.setState({subscribed: false})
        this.emailRef.current.value = "";
    }

    render() {
        return (
            <div className="newsletter-signup-container">
                <h2 className={`widget-title`}>Anthology V2 Now Available</h2>
                <p>The latest iteration of our terrifying namesake short story collection is now available. </p>
                <div className="space-10"/>
                <img src={AnthologyCoverV2} alt="thumb" className="review-book-image"/>
                <div className="space-10"/>
                <div className="signup_form">
                    <a href="https://a.co/d/aZTnEUT" className="full">
                        <Button variant="primary" className="email-signup-button" onClick={this.submitClicked}>
                            BUY NOW
                        </Button>
                    </a>
                    <div className="space-10"/>
                </div>
            </div>
        );
    }
};