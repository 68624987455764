import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import './CreateAccountModal.css';

export default class CommunityGuidelinesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Community Guidelines
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-content">
                        <div className="businerss_news">
                            <p>Last updated: May 12, 2021</p>
                            <p>These guidelines are meant to ensure that a safe, inclusive, and respectful community is created at Strangest Fiction. The guidelines can be expanded or adjusted at any time at the discretion of the administrators of the site.</p>
                            <h2>Story Content</h2>
                            <ul>
                            <li>
                            <p>Stories that express an author's direct opinion of sexist, racist, homophobic, xenophobic, or other hateful content are prohibited. Character's in stories may express these opinions only if the writer specifies <b>Mature Content</b> so the user can decide whether to proceed.</p>
                            </li>
                            <li>
                            <p>Stories containing graphic, violent, sexual, or other mature topics must specify <b>Mature Content</b> to warn the reader. In addition, we have the right to restrict any story that we deem too sexually graphic or violent in order to protect the readers on our site.</p>                            </li>
                            <li>
                            <p>Stories must contain <b>mostly correct</b> grammar and sentence structure. They must be written in English and contain an intermediate to advanced grasp of the written English language.</p>
                            </li>
                            <li>
                            <p>Stories aimed at attacking specific individuals are strictly prohibited.</p>
                            </li>
                            </ul>
                            <h2>Story Ownership</h2>
                            <ul>
                            <li>
                            <p>Stories are ultimately the complete intelectual property of the writer. Stories can be removed from the site at anytime by the writer through the <b>Account Page</b>.</p>
                            </li>
                            <li>
                            <p>While a user's story is posted on Strangest Fiction, administrators are allowed to use their stories for advertisements, newsletters, and other forms of marketing. Additional use of stories must be requested from the writer by administration.</p>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <div className="center-content">
                        <Button variant="secondary" size="lg" className="pad-right-10" onClick={() => {this.props.closeModal()}}>
                            Accept
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}