import React, {Component} from 'react';
import {Link} from "react-router-dom";

import '../components/primary-styles.css';

import StoryPreview from './StoryPreview';
import { connect } from 'react-redux';

import {logPageView} from '../FirebaseActions';

class AllStories extends React.Component {
    constructor(props) {
      super(props);
      this.state = {   
          sortedStories: null   
      };

      this.sortSelector = React.createRef();

      this.sortClicked = this.sortClicked.bind(this);
    }

    storyClicked(story) {
        this.props.history.push('/story/' + story.id);
    }

    sortClicked() {
        //console.log(this.sortSelector.current.value);
        if (this.sortSelector.current.value == 'date') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return b.timestamp - a.timestamp;})});
        } else if (this.sortSelector.current.value == 'mostreads') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return (b.allStoryInformation.viewcount) - (a.allStoryInformation.viewcount)})});
        } else if (this.sortSelector.current.value == 'mostreact') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return (b.allStoryInformation.commentsCount + b.allStoryInformation.thumbsUp.length) - (a.allStoryInformation.commentsCount + a.allStoryInformation.thumbsUp.length)})});
        } else if (this.sortSelector.current.value == 'longest') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return b.allStoryInformation.storyText.split(' ').length - a.allStoryInformation.storyText.split(' ').length})});
        } else if (this.sortSelector.current.value == 'shortest') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return a.allStoryInformation.storyText.split(' ').length - b.allStoryInformation.storyText.split(' ').length})});
        } else if (this.sortSelector.current.value == 'poplate') {
            let sortedByDate = this.state.sortedStories.sort((a, b) => { return b.timestamp - a.timestamp;});
            let recentPortion = Math.round(sortedByDate.length / 3);
            let firstPart = sortedByDate.slice(0, recentPortion);
            firstPart.sort((a, b) => { return (b.allStoryInformation.viewcount + b.allStoryInformation.thumbsUp.length) - (a.allStoryInformation.viewcount + a.allStoryInformation.thumbsUp.length)});
            let secondPart = sortedByDate.slice(-(sortedByDate.length - recentPortion));
            this.setState({sortedStories: firstPart.concat(secondPart)});
        }
    }

    componentDidMount() {
        logPageView(window.location.pathname);
        this.setState({sortedStories: this.props.allStories.sort((a, b) => { return b.timestamp - a.timestamp;})});
    }
  
    render() {
        return (
            <>
             {/* <div className="widget-title top-stories-title">Top Stories</div> */}
             <div className="sort-by-row">
                <div className="sort-by-container">
                    <div className="sort-by">Sort By:</div>
                    <select className="sorting-dropdown" name="options" id="sort-selector" onChange={this.sortClicked} ref={this.sortSelector}>
                        <option value="date">Date Added</option>
                        <option value="poplate">Popular Lately</option>
                        <option value="mostreads">Most Reads</option>
                        <option value="mostreact">Most Reactions</option>
                        <option value="longest">Longest</option>
                        <option value="shortest">Shortest</option>
                    </select>
                </div>
            </div>
            <div className="straight-line"></div>
             <div className="row">
                 {this.state.sortedStories != null && this.state.sortedStories.map((story, index) => {
                     if (story.hidden == null && story.allStoryInformation.competitionId == null) {
                                                                            
                        let userInfo = this.props.allUsers.find(o => o.uid === story.creatorID);

                        return (
                            <StoryPreview story={story} userInfo={userInfo} colWidth={4} />
                         )
                    }
                 })}
             </div>
             <div className="experiencing-issues-question">
                Experiencing issues with the site or have questions? Contact us <Link to="/contact">here</Link>.
            </div>
            </>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        allPrompts: state.primary.allPrompts,
        checkForUser: state.primary.checkForUser
    }
}

export default connect (mapStateToProps) (AllStories)