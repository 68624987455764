import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import unsolicitedImage from '../../new_images/unsolicited.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

export default class ReviewUnsolicited extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">Unsolicited Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={unsolicitedImage} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link>Thriller</Link>
                                                        <Link>March 8th, 2021</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>Unsolicited - Julie Kaewert</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                            Unsolicited tells the story of a London publishing house that grew substantially from the success of an anonymous authors' novel about kidnappings taking place during World War II. The system works well until one day the author's submissions stopped with a few chapters left before the completion of his newest novel. 
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        USERS VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            Unsolicited is an engaging story about a London publishing family, the Plumtrees, and their youngest son, Alex Plumtree, who has to take control of the business after the death of his parents. Alex expanded the reach of his father’s business greatly due to the publishing of unsolicited submissions from an anonymous author writing historical fiction novels about kidnappings during World War II. The arrangement is quite profitable for Plumtree press until they stop receiving submissions from the author and assume that he has gone missing.
                                            </p>
                                            <p>
                                            Alex then goes on a wild journey to find the author and events unfold around him that put not only himself but also the publishing house at risk of death. The story will keep you guessing with a broad array of characters, almost all of whom Alex finds reasons to question the loyalty of. The writing of Unsolicited is entertaining and engaging, I often found paragraphs to flow like songs in my head. The writer does an incredible job at constructing images in the reader's mind and putting them right in Alex’s shoes all the time.
                                            </p>
                                            <p>
                                            Unsolicited tells a story of achieving familial expectations while also carving out a name for yourself and being an individual. It also shows how vitally important it is to hang on to loyal friends because even when the world is falling apart around you, you still will have them to depend on. Not to mention that sometimes our written history isn’t always black and white, there are always untold stories still to be discovered.
                                            </p>
                                            <p>
                                            I would undoubtedly recommend this book! It is available on Amazon in hardcover and E-Book. There is also a string of sequel novels in the “Booklover’s Mysteries” series if you enjoyed this one.
                                            </p>
                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="unsolicited" currentUser={this.props.currentUser} firebase={this.props.firebase}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <CategoriesWidget/>
                            <NewsLetter firebase={this.props.firebase}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
            <ThreeRecentStories allStories={this.props.allStories} allUsers={this.props.allUsers} history={this.props.history}/>
        </Fragment>
        )
    }
};