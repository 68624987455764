import React, {Fragment} from 'react';

import './CreateAccountModal.css';

import PublishedMessage from './PublishedMessage';
import ConfirmPublishModal from './ConfirmPublishModal';
import BlockedErrorModal from '../modals/BlockedErrorModal';
import { getDBRef } from '../FirebaseActions';
import {connect} from 'react-redux';

class PublishModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPublished: false,
            storyID: null,
            showBlockedModal: false
        };

        this.publishClicked = this.publishClicked.bind(this);
    }

    publishClicked() {
        // Do firebase stuff
        let db = getDBRef();

        if (this.props.currentUser.blocked == null) {
            try {
                let currentTime = Date.now();
                //console.log(this.props.currentUser)
                let newStoryRef = db.collection("users").doc(this.props.currentUser.uid).collection("stories").doc();
                newStoryRef.set({
                    allStoryInformation: this.props.allStoryInformation,
                    id: newStoryRef.id
                })
    
                db.collection("users").doc(this.props.currentUser.uid).update({
                    numstories: this.props.currentUser.numstories + 1
                })

                if (this.props.selectedPromptId != null) {
                    let newStoryIds = this.props.allPrompts.find(element => element.id == this.props.selectedPromptId).storyIds;
                    newStoryIds.push(newStoryRef.id);
                    db.collection("prompts").doc(this.props.selectedPromptId).update({
                        storyIds: newStoryIds
                    })
                }

                if (this.props.allStoryInformation.competitionId != null) {
                    let newStoryIds = this.props.allCompetitions.find(element => element.id == this.props.allStoryInformation.competitionId).storyIds;
                    newStoryIds.push(newStoryRef.id);
                    db.collection("competitions").doc(this.props.allStoryInformation.competitionId).update({
                        storyIds: newStoryIds
                    })
                }
    
                db.collection("all-stories").doc(newStoryRef.id).set({
                    allStoryInformation: this.props.allStoryInformation,
                    id: newStoryRef.id,
                    creatorID: this.props.currentUser.uid,
                    profileImg: this.props.currentUser.imgUrl,
                    timestamp: currentTime
                })
    
                this.setState({showPublished: true, storyID: newStoryRef.id});
    
                //this.props.reloadAllStories();
            } catch(error) {
                console.log(error);
            }
        } else {
            this.setState({showBlockedModal: true});
        }
    }

    render() {
        return ( 
            <>
                {this.state.showPublished && (
                    <PublishedMessage storyID={this.state.storyID} allStoryInformation={this.props.allStoryInformation} showModal={this.props.showModal} onHide={this.props.goToAccount} closeModal={this.props.goToAccount}/>
                )}
                {!this.state.showPublished && (
                    <ConfirmPublishModal allStoryInformation={this.props.allStoryInformation} showModal={this.props.showModal} onHide={this.props.closeModal} closeModal={this.props.closeModal} publishClicked={this.publishClicked}/>
                )}
                <BlockedErrorModal showModal={this.state.showBlockedModal} closeModal={() => {this.setState({showBlockedModal: false})}}/>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts,
        allCompetitions: state.primary.allCompetitions
    }
}

export default connect (mapStateToProps) (PublishModalContainer)