import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import theTombImage from '../../new_images/the_tomb.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

export default class ReviewTheTomb extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">The Tomb Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={theTombImage} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link>Supernatural</Link><Link>Thriller</Link>
                                                        <Link>March 8th, 2021</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>The Tomb - F. Paul Wilson</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                                The Tomb places the standard sharp, muscular, and covert personal detective character into an unfamiliar and supernatural environment.                                             </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        USERS VOTE
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            Buy it
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            The Tomb tells two main stories: a damaged personal detective prioritizing family over business and that of a centuries-old curse that plagues a well-established British family. While both stories have been done before in many forms, The Tomb takes a unique approach to each. The character of Repairman Jack (standard personal detective character) is alluring to read because he possesses no special abilities: no weightlifting records, no sniper school or military training, and surely no supernatural gifts. The character is self-trained and self-made. He doesn’t possess a cold heart to make him more effective at his job. Instead, he is often influenced by which of his clients seem the most in need of his service. While he is influenced by money, his heart continues to control more of his actions throughout the story. What’s even more special about this story is that rather than Repairman Jack stumbling into the supernatural horror of the family curse and having to solve it, he gets intertwined in the drama in more ways than one. It becomes a mystery that he needs to solve and makes it even more addicting for the reader. 
                                            </p>
                                            <p>
                                            I finished this book in about a day and a half. I couldn’t put it down as soon as I started and I’m sure you will feel the same. The Keep is available in hard copy and E-Book from Amazon! 
                                            </p>
                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="the_tomb" currentUser={this.props.currentUser} firebase={this.props.firebase}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <CategoriesWidget/>
                            <NewsLetter firebase={this.props.firebase}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
            <ThreeRecentStories allStories={this.props.allStories} allUsers={this.props.allUsers} history={this.props.history}/>
        </Fragment>
        )
    }
};