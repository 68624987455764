import React, {useState, useRef, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import { toFullMonth, formatAMPM } from '../../utils/commonFunctions';

import previousIcon from '../../new_images/icons/previous-icon.png';
import darkUserIcon from '../../new_images/icons/dark-user-icon.png';
import { getDBRef } from '../../FirebaseActions';

const ChatWindow = (props) => {

    const messageContentRef = useRef(null);
    const [newMessage, setNewMessage] = useState("");
    const [isFirstMessage, setIsFirstMessage] = useState(props.isFirstMessage);

    const db = getDBRef();

    const readMessage = () => {
        if (props.chatInfo && !props.chatInfo.hasRead) {
            db.collection("users").doc(props.currentUser.uid).collection('messages').doc(props.chatInfo.id).update({
                hasRead: true
            }).then(() => {
                props.updateMessages();
            })
        }
    }

    const delayedScroll = () => {
        setTimeout(() => {
            var objDiv = messageContentRef.current;
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 200);
    }

    const sendMessage = (e) => {
        //console.log(e.key);
        if (!isFirstMessage && (e.key === "Enter" || !e.key)) {
            let newMessages = [...props.chatInfo.messages];
            //console.log(newMessages);

            // const snapshot = await db.collection('users').doc(props.currentUser.uid).collection('messages').orderBy("lastSent", "asc").get();
            // console.log(snapshot.docs.map(doc => doc.data()));

            newMessages.push({text: newMessage, userId: props.currentUser.uid, timestamp: Date.now()})

            // Sender db messages update
            db.collection("users").doc(props.currentUser.uid).collection('messages').doc(props.chatInfo.id).update({
                'messages': newMessages,
                'hasRead': true,
                'lastSent': Date.now()
            }).then(() => {
                // Receiver db messages update
                db.collection("users").doc(props.userInfo.uid).collection('messages').doc(props.chatInfo.id).update({
                    'messages': newMessages,
                    'hasRead': false,
                    'lastSent': Date.now()
                }).then(() => {
                    // Update the messages in props
                    props.updateMessages();
                    delayedScroll();
                })

                // Send receiver a notification
                db.collection("users").doc(props.userInfo.uid).update({
                    hasActiveMessage: true
                })
            })

            setNewMessage("");
        } else if (isFirstMessage && (e.key === "Enter" || !e.key)) {
            let newMessageSenderRef = db.collection("users").doc(props.currentUser.uid).collection('messages').doc();

            let chatInfoObj = {
                id: newMessageSenderRef.id,
                messages: [{text: newMessage, userId: props.currentUser.uid, timestamp: Date.now()}],
                lastSent: Date.now(),
                userId: props.userInfo.uid,
                hasRead: true
            }

            newMessageSenderRef.set(chatInfoObj).then(() => {
                let newMessageReceiverRef = db.collection("users").doc(props.userInfo.uid).collection('messages').doc(newMessageSenderRef.id);
                newMessageReceiverRef.set({
                    id: newMessageSenderRef.id,
                    messages: [{text: newMessage, userId: props.currentUser.uid, timestamp: Date.now()}],
                    lastSent: Date.now(),
                    userId: props.currentUser.uid,
                    hasRead: false
                }).then(() => {
                    props.updateMessages();
                    props.updateChatInfo(chatInfoObj);
                    setIsFirstMessage(false);
                    delayedScroll();
                }).catch((e) => {
                    console.log(e);
                })
            });

            // Send receiver a notification
            db.collection("users").doc(props.userInfo.uid).update({
                hasActiveMessage: true
            })

            setNewMessage("");
        }
    }

    useEffect(() => {
        readMessage();
        delayedScroll();
    }, [])

    return (
        <div>
            <div className="message-user-bar">
                <div className="center-vert">
                    <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => props.hideSingle(false)}/>
                </div>
                <div className="together">
                    {props.userInfo.imgUrl != null && (
                        <img src={props.userInfo.imgUrl} alt="profile-image" className="image"/>
                    )}
                    {props.userInfo.imgUrl == null && (
                        <img src={darkUserIcon} alt="profile-image" className="image"/>
                    )}
                    <div className="names">
                        {props.userInfo.fullname != "" && (
                            <div className="full">
                                {props.userInfo.fullname}
                            </div>
                        )}
                        <div className="username">
                            @{props.userInfo.username}
                        </div>
                    </div>
                </div>
                <div className="center-vert">
                    <Link to={`/user/${props.currentUser.uid}`}><Button variant="primary" className="view-profile-button">Profile</Button></Link>
                </div>
            </div>
            <div className="message-content" ref={messageContentRef}>
                {isFirstMessage && (
                    <>
                        <div className='send-first-message'>Send your first message to {props.userInfo.username}!</div>
                    </>
                )}
                {!isFirstMessage && (
                    <>
                        {props.chatInfo.messages.map((message) => {
                            // console.log("COMPARISON");
                            // console.log(message);
                            // console.log(message.userId);
                            // console.log(props.currentUser.uid);

                            const messageDate = new Date(message.timestamp);
                            const dateString = toFullMonth(messageDate.getMonth()) + " " + messageDate.getDate() + ", " + messageDate.getFullYear() + ", " + formatAMPM(messageDate);

                            if (message.userId === props.currentUser.uid) {
                                return (
                                    <>
                                        <div className="message-timestamp">{dateString}</div>
                                        <div className='align-right'>
                                            <div className="cur-user-message">{message.text}</div>
                                        </div>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <div className="message-timestamp">{dateString}</div>
                                        <div className="other-user-message">{message.text}</div>
                                    </>
                                )
                            }
                        })}
                    </>
                )}
            </div>
            <div className="send-message">
                <textarea rows="1" onChange={(e) => {setNewMessage(e.target.value)}} value={newMessage} id="description-input" className="account-info-input" onKeyDown={sendMessage}/>
                <Button variant="primary" className="send-message-button" onClick={sendMessage}>Send</Button>
            </div>
        </div>
    )
}

export default ChatWindow;