import React, {Fragment} from 'react';
import { useEffect, useState } from 'react';
import BreadCrumb from "../components/BreadCrumb";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import './CategoryPages.css';
import '../components/primary-styles.css';
import StoryPreview from './StoryPreview';
import SideFeedContainer from './SideFeedContainer';
import { useSelector, useDispatch } from 'react-redux';

const PromptPage = (props) => {

    const [prompt, setPrompt] = useState(null);

    const allPrompts = useSelector(state => state.primary.allPrompts);
    const currentUser = useSelector(state => state.primary.currentUser);
    const allUsers = useSelector(state => state.primary.allUsers);
    const allStories = useSelector(state => state.primary.allStories);

    const dispatch = useDispatch();

    // const closeModalFromWrite = () => {
    //     setShowModal(false);
    //     if (loginWasSuccess) {
    //         setTimeout(() => {
    //             this.props.history.push('/write');
    //         }, 300)
    //         setLoginWasSuccess(false);
    //     }
    // }

    // const wasSuccess = () => {
    //     setLoginWasSuccess(true);
    // }


    useEffect(() => {
        let storyPrompt = allPrompts.find(element => element.id == (window.location.pathname).slice(8));
        setPrompt(storyPrompt);
    })

    return (
        <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        {prompt != null && (
                            <div className="row row-eq-height">
                                <div className="col-lg-9">
                                    <div className="businerss_news">
                                        {/* <div className="row">
                                            <div className="col-12 align-self-center no-padding">
                                                <div className="category-title-container">
                                                    <img src={histFicBigImage} alt="category-title" />
                                                    <div className="category-title"><h2>{prompt.title}</h2></div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='prompt-header'>
                                            <h2>{prompt.title}</h2>
                                            <div className='prompt-description'>{prompt.storyIds.length} Submission(s) - Created {new Date(prompt.timestamp).toLocaleDateString("en-US")}</div>
                                        </div>

                                        {currentUser != null && (
                                            <Link to={`/write?prompt=${prompt.id}`}>
                                                <Button variant="primary" size="lg" className="add-story-button">Add Story</Button>
                                            </Link>
                                        )}
                                        {currentUser == null && (
                                            <Button variant="primary" size="lg" className="add-story-button" onClick={() => {dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true,showCreateAccount: true,showSuccess: false,showPassReset: false}});}}>Add Story</Button>
                                        )}
                                        
                                        <div className="row">
                                            {prompt.storyIds != null && prompt.storyIds.length != 0 && prompt.storyIds.map((storyId, index) => {
                                                    
                                                let storyInfo = allStories.find(o => o.id === storyId);

                                                if(storyInfo != null) {
                                                    let userInfo = allUsers.find(o => o.uid === storyInfo.creatorID);

                                                    if (storyInfo.hidden == null && storyInfo.allStoryInformation.competitionId == null) {
                                                        return (
                                                            <StoryPreview story={storyInfo} userInfo={userInfo} colWidth={4}/>
                                                        )
                                                    }
                                                } 
                                                // else {
                                                //     let newStoryIds = allPrompts.find(element => element.id == prompt.id).storyIds;
                                                //     delete newStoryIds[storyId];
                                                //     let db = props.firebase.firestore();
                                                //     db.collection("prompts").doc(prompt.id).update({
                                                //         storyIds: newStoryIds
                                                //     })
                                                // }
                                            })}
                                        </div>
                                        {prompt.storyIds == null || prompt.storyIds.length == 0 && (
                                                <b>No stories added yet! Click the button to add a story to this prompt! </b>
                                        )}
                                        {/* <PromptUserToWrite reloadAllStories={props.reloadAllStories} currentUser={props.currentUser} allUsers={props.allUsers} firebase={props.firebase} history={props.history}/> */}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <SideFeedContainer/>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </Fragment>

    )
}

export default PromptPage;