import React, {Component, useEffect} from 'react';
import {Link} from "react-router-dom";
import { PayPalDonationButton } from './PayPalDonationButton';

import './NewSideBar.css';

const NewSideBar = (props) => {

    // useEffect(() => {
    //     if (props.sideShow) document.getElementsByClassName('dark-theme')[0].style.opacity = "0.5";
    // }, [props])

        return (
            <>
                {props.sideShow && (
                    <div className="hamburger-section">
                        <Link className="mobile-nav-link" to="/" onClick={() => {props.setSideShow(false)}}>Home</Link>
                        <Link className="mobile-nav-link" to="/announcements" onClick={() => {props.setSideShow(false)}}>Announcements</Link>
                        <Link className="mobile-nav-link" to="/reviews" onClick={() => {props.setSideShow(false)}}>Reviews</Link>
                        <div className="mobile-nav-header">Shop</div>
                        <div className="pad-30-l">
                            <Link className="mobile-nav-link" to="/publications" onClick={() => {props.setSideShow(false)}}>Publications</Link>
                            <Link className="mobile-nav-link" to="/merch" onClick={() => {props.setSideShow(false)}}>Merch</Link>
                        </div>
                        <div className="mobile-nav-header">Short Stories</div>
                        <div className="pad-30-l">
                            <Link className="mobile-nav-link" to="/allstories" onClick={() => {props.setSideShow(false)}}>All Stories</Link>
                            <Link className="mobile-nav-link" to="/categories" onClick={() => {props.setSideShow(false)}}>Categories</Link>
                            <Link className="mobile-nav-link" to="/writers" onClick={() => {props.setSideShow(false)}}>Writers</Link>
                        </div>
                        <div className="mobile-nav-header">Archive</div>
                        <div className="pad-30-l">
                            <Link className="mobile-nav-link" to="/prompts" onClick={() => {props.setSideShow(false)}}>Prompts</Link>
                            <Link className="mobile-nav-link" to="/competitions" onClick={() => {props.setSideShow(false)}}>Competitions</Link>
                        </div>
                        {/* <Link className="mobile-nav-link" to="/reviews" onClick={() => {this.props.setSideShow(false)}}>Reviews</Link> */}
                        <Link className="mobile-nav-link" to="/about" onClick={() => {props.setSideShow(false)}}>About</Link>
                        <Link className="mobile-nav-link" to="/contact" onClick={() => {props.setSideShow(false)}}>Contact</Link>
                        <a className="mobile-nav-link-donate" href="https://www.paypal.com/donate/?hosted_button_id=U4LG3HKKKJFX8" onClick={() => {props.setSideShow(false)}}>Donate</a>
                    </div>             
                )}
            </>
        );
};

export default NewSideBar;