import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import './CreateAccountModal.css';
import { connect } from 'react-redux';

class PromptCreateAccountModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    createAccountClicked() {
        this.props.dispatch({type: "SET_LOGIN_MODALS", payload: {
            showLoginModal: true,
            showCreateAccount: true,
            showSuccess: false,
            showPassReset: false
        }});
        this.props.dispatch({type: "SET_PROMPT_CREATE_ACCOUNT_MODAL", payload: false});
    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Welcome to Strangest Fiction!
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-content">
                        <div className="short-description">
                            We are a community of short fiction writers and readers creating the "strangest" short stories available. Create an account today to get started!
                        </div>
                    </div>
                    <div className="center-content">
                        <div className="cookie-notice">
                            This site uses cookies to enhace user experience. By closing this popup you are granting permission for them to be used. Full details on privacy policy page.
                        </div>
                    </div>
                    <div className="center-content">
                        <Button variant="secondary" className="pad-right-10" onClick={() => {this.props.closeModal()}}>
                            Close
                        </Button>
                        <Button variant="primary" className="pad-right-10" onClick={() => {this.createAccountClicked()}}>
                            Create Account
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}

export default connect () (PromptCreateAccountModal)