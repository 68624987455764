import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import {Link} from "react-router-dom";

import '../components/primary-styles.css';
import PromptUserToWrite from './PromptUserToWrite';

import './NewsletterPage.css';
import SideFeedContainer from './SideFeedContainer';

export default class NewsletterPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h3>Announcements</h3>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="businerss_news">
                                    {/* <div className="row">
                                        <div className="col-12"> */}
                                            {/* This is where the top stories list starts */}
                                            <Link to="/publications/anthology-vol-2" className="newsletter-container"> 
                                                <div className="newsletter-title">
                                                    Strangest Fiction Anthology - Volume 2
                                                </div>
                                                <div className="newsletter-description">
                                                    We are beyond thrilled to announce that the Strangest Fiction Anthology - Volume 2 is now available for purchase in Print and eBook! We would like to thank all our talented authors and team members for their contributions to bring this collection of horror, supernatural, and science fiction works to life!
                                                </div>
                                                <i>07/26/24</i>
                                            </Link>
                                            <Link to="/submit/campfire-v1" className="newsletter-container"> 
                                                <div className="newsletter-title">
                                                    Now Accepting Submission for Strangest Fiction Campfire Stories V1
                                                </div>
                                                <div className="newsletter-description">
                                                    We are excited to announce that we are now accepting submissions for the Strangest Fiction Campfire Stories collection. Click to find out more and submit your favorite story on our updated platform.
                                                </div>
                                                <i>08/19/23</i>
                                            </Link>
                                            <Link to="/publications/anthology-vol-1" className="newsletter-container"> 
                                                <div className="newsletter-title">
                                                    Strangest Fiction Anthology - Volume 1
                                                </div>
                                                <div className="newsletter-description">
                                                    We are beyond thrilled to announce that the Strangest Fiction Anthology - Volume 1 is now available for purchase on Amazon! We would like to thank all our talented authors and team members for their contributions to bring this collection of horror, supernatural, science fiction, fantasy, and thriller works to life!
                                                </div>
                                                <i>10/31/22</i>
                                            </Link>
                                            <Link to="/announcements/anthology" className="newsletter-container"> 
                                                <div className="newsletter-title">
                                                    Strangest Fiction Anthology - Coming October 2022!
                                                </div>
                                                <div className="newsletter-description">
                                                    Post stories to be considered for our upcoming publication, scheduled for release in fall 2022!
                                                </div>
                                                <i>6/11/22</i>
                                            </Link>
                                            <a className="newsletter-container" href="https://mailchi.mp/f73b561da5c7/strangest-fiction-news-and-updates?e=9654f0ec8a">
                                                <div className="newsletter-title">
                                                    Strangest Fiction News - February 2022
                                                </div>
                                                <div className="newsletter-description">
                                                    This week in SF news we'll highlight some of the new features on our site such as donations, a prompts section, and link sharing!
                                                </div>
                                                <i>2/1/22</i>
                                            </a>
                                            <a className="newsletter-container" href="https://mailchi.mp/a65f0f0e621c/strangest-fiction-news-week-of-august-23rd">
                                                <div className="newsletter-title">
                                                    Strangest Fiction News - August 2021
                                                </div>
                                                <div className="newsletter-description">
                                                    This week in SF news we'll highlight some of the new features on the site and offer YOU the opportunity to tell us what you want to see in the future.
                                                </div>
                                                <i>8/1/21</i>
                                            </a>
                                            <a className="newsletter-container" href="https://mailchi.mp/25f1fe34065d/uyigc4jgq4-4888786">
                                                <div className="newsletter-title">
                                                    Strangest Fiction News - April 2021
                                                </div>
                                                <div className="newsletter-description">
                                                    Welcome to the first official Strangest Fiction news letter! We appreciate your support as one of the first members of our inclusive community of "strange" fiction writers. Each week we will feature a few of the newest and most though-provoking stories by writers on our platform.
                                                </div>
                                                <i>4/1/21</i>
                                            </a>
                                        <PromptUserToWrite/>
                                        {/* </div>
                                    </div> */}
                                </div>

                            </div>
                            <div className="col-lg-3">
                                <SideFeedContainer showCategories={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};