import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import NewsLetter from "../components/NewsLetter";
import InstagramLink from './InstagramLink';

import '../components/primary-styles.css';

import CategoriesWidget from '../components/CategoriesWidget';
import PromptUserToWrite from './PromptUserToWrite';

import StoryPreview from './StoryPreview';

import FillOutSurvey from '../components/FillOutSurvey';
import SideFeedContainer from './SideFeedContainer';
import { PayPalDonationButton } from '../components/PayPalDonationButton';
import {connect} from 'react-redux';
import {logPageView} from '../FirebaseActions';

class AllStoriesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortedStories: null
        };

        this.sortSelector = React.createRef();

        this.sortClicked = this.sortClicked.bind(this);
    }

    sortClicked() {
        //console.log(this.sortSelector.current.value);
        if (this.sortSelector.current.value == 'date') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return b.timestamp - a.timestamp;})});
        } else if (this.sortSelector.current.value == 'mostreads') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return (b.allStoryInformation.viewcount) - (a.allStoryInformation.viewcount)})});
        } else if (this.sortSelector.current.value == 'mostreact') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return (b.allStoryInformation.commentsCount + b.allStoryInformation.thumbsUp.length) - (a.allStoryInformation.commentsCount + a.allStoryInformation.thumbsUp.length)})});
        } else if (this.sortSelector.current.value == 'longest') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return b.allStoryInformation.storyText.split(' ').length - a.allStoryInformation.storyText.split(' ').length})});
        } else if (this.sortSelector.current.value == 'shortest') {
            this.setState({sortedStories: this.state.sortedStories.sort((a, b) => { return a.allStoryInformation.storyText.split(' ').length - b.allStoryInformation.storyText.split(' ').length})});
        } else if (this.sortSelector.current.value == 'poplate') {
            let sortedByDate = this.state.sortedStories.sort((a, b) => { return b.timestamp - a.timestamp;});
            let recentPortion = Math.round(sortedByDate.length / 3);
            let firstPart = sortedByDate.slice(0, recentPortion);
            firstPart.sort((a, b) => { return (b.allStoryInformation.viewcount + b.allStoryInformation.thumbsUp.length) - (a.allStoryInformation.viewcount + a.allStoryInformation.thumbsUp.length)});
            let secondPart = sortedByDate.slice(-(sortedByDate.length - recentPortion));
            this.setState({sortedStories: firstPart.concat(secondPart)});
        }
    }

    componentDidMount() {
        this.setState({sortedStories: this.props.allStories.sort((a, b) => { return b.timestamp - a.timestamp;})});
        logPageView(window.location.pathname);
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h3>All Stories ({this.props.allStories.length})</h3>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="businerss_news">
                                    <div className="sort-by-row">
                                        <div className="sort-by-container">
                                            <div className="sort-by">Sort By:</div>
                                            <select className="sorting-dropdown" name="options" id="sort-selector" onChange={this.sortClicked} ref={this.sortSelector}>
                                                <option value="date">Date Added</option>
                                                <option value="poplate">Popular Lately</option>
                                                <option value="mostreads">Most Reads</option>
                                                <option value="mostreact">Most Reactions</option>
                                                <option value="longest">Longest</option>
                                                <option value="shortest">Shortest</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.sortedStories != null && this.state.sortedStories.map((story, index) => {
                                            if (story.hidden == null && story.allStoryInformation.competitionId == null) {
                                                                                                    
                                                let userInfo = this.props.allUsers.find(o => o.uid === story.creatorID);

                                                return (
                                                    <StoryPreview story={story} userInfo={userInfo} colWidth={4}/>
                                                )
                                            }
                                        })}
                                            
                                    </div>
                                    <PromptUserToWrite/>
                                </div>

                            </div>
                            <div className="col-lg-3">
                                <SideFeedContainer/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers
    }
}

export default connect (mapStateToProps) (AllStoriesPage)