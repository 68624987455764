import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";

import './CategoryPages.css';

import '../components/primary-styles.css';

import './UserPage.css';

import tagIcon from '../new_images/pricetag-icon.png';
import eyeIcon from '../new_images/icons/blue-eye-icon.png';
import bluePenIcon from '../new_images/icons/blue-pen-icon.png';
import commentsIcon from '../new_images/icons/white-comment-icon.png';
import adminIcon from '../new_images/icons/admin-icon.png';

import instaIcon from '../new_images/sm-logos/instagram-colored-icon.png';
import twitterIcon from '../new_images/sm-logos/twitter-colored-icon.png';
import linkIcon from '../new_images/sm-logos/colored-link-icon.png';
import darkUserIcon from '../new_images/icons/dark-user-icon.png';
import thumbIcon from '../new_images/icons/white-thumb-icon.png';
import Button from "react-bootstrap/Button";
import plusIcon from '../new_images/icons/white-plus-icon.png';

import StoryPreview from './StoryPreview';
import { getInitialDataFB, logPageView } from '../FirebaseActions';
import {connect} from 'react-redux';

class UserPage extends React.Component {

    constructor(props) {
        super(props);

        this.infoButtonRef = React.createRef();
        this.storiesButtonRef = React.createRef();
        this.feedButtonRef = React.createRef();

        this.state = {
            userInfo: this.props.allUsers.find(element => element.uid == (window.location.pathname).slice(6)),
            userStories: null,
            totalStories: 0,
            totalViews: 0,
            totalLikes: 0,
            totalComments: 0,
            showInfo: true,
            showStories: false,
            showFeed: false
        };

        this.setButtonUnderline = this.setButtonUnderline.bind(this);
        this.messageClicked = this.messageClicked.bind(this);
    }

    matchesID(story) {
        //console.log(story);
        //console.log((window.location.pathname).slice(6))
        return story.creatorID == (window.location.pathname).slice(6);
    }

    getAdditionalUserInfo() {
        let userStories = this.props.allStories.filter(this.matchesID);
        let viewcount = 0;
        let thumbcount = 0;
        let commentcount = 0;

        for (let i = 0; i < userStories.length; i++) {
            viewcount += userStories[i].allStoryInformation.viewcount;
            thumbcount += userStories[i].allStoryInformation.thumbsUp.length;
            commentcount += userStories[i].allStoryInformation.commentsCount;
        }

        this.setState({userStories: userStories, totalViews: viewcount, totalComments: commentcount, totalLikes: thumbcount})
    }

    storyClicked(story) {
        this.props.history.push('/story/' + story.id);
    }

    setButtonUnderline(buttonRef, refToDelete, otherRefToDelete) {
        if (!buttonRef.current.classList.contains("blue-underline")) {
            buttonRef.current.classList.add("blue-underline");
        }
        refToDelete.current.classList.remove("blue-underline");
        otherRefToDelete.current.classList.remove("blue-underline");
    }

    messageClicked() {
        if (this.props.currentUser) {
            this.props.history.push(`/account?messages&sendMessageTo=${this.state.userInfo.uid}`);
        } else {

        }
    }

    componentDidMount() {
        this.getAdditionalUserInfo();
        logPageView(window.location.pathname);
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    {this.state.userInfo != null && (
                        <div className="container">
                            <div className="user-navbar">
                                <div className="user-left-info">
                                        {this.state.userInfo.imgUrl != null && (
                                            <img src={this.state.userInfo.imgUrl} alt="profile-image" className="user-navbar-image"/>
                                        )}
                                        {this.state.userInfo.imgUrl == null && (
                                            <img src={darkUserIcon} alt="profile-image" className="user-navbar-image"/>
                                        )}
                                        <div className="user-navbar-names">
                                            {this.state.userInfo.fullname != "" && (
                                                <div className="user-navbar-fullname">
                                                    {this.state.userInfo.fullname}
                                                </div>
                                            )}
                                            <div className="user-navbar-username">
                                                @{this.state.userInfo.username}
                                            </div>
                                        </div>
                                </div>

                                <div className='user-right-info'>
                                    <div className="user-stats-row">
                                            <div className="center-content">
                                                <div className="total-stats-title">Total Stats</div>
                                            </div>
                                            <div className="flex-row wrap-me">
                                                <div className="flex-row large-stat">
                                                    <img src={bluePenIcon} alt="Views icon" className="eye-icon" />
                                                    <div className="story-views-blue">{this.state.userStories != null && (<>{this.state.userStories.length} </>)}</div>
                                                </div>
                                                <div className="flex-row large-stat">
                                                    <img src={eyeIcon} alt="Views icon" className="eye-icon" />
                                                    <div className="story-views-blue">{this.state.totalViews}</div>
                                                </div>
                                                <div className="flex-row large-stat">
                                                    <img src={thumbIcon} alt="Thumbs up icon" className="eye-icon" />
                                                    <div className="story-views">{this.state.totalLikes}</div>
                                                </div>
                                                <div className="flex-row">
                                                    <img src={commentsIcon} alt="Thumbs up icon" className="eye-icon" />
                                                    <div className="story-views">{this.state.totalComments}</div>
                                                </div>
                                            </div>
                                    </div>

                                    <Button variant="primary" onClick={this.messageClicked}>Message<img src={plusIcon} alt="plus icon" className="tiny-plus"/></Button>
                                </div>
                            </div>

                            <div className="user-navbar-lower">
                                <div className="user-navbar-lower-tab blue-underline" ref={this.infoButtonRef} onClick={() => {this.setButtonUnderline(this.infoButtonRef, this.storiesButtonRef, this.feedButtonRef); this.setState({showInfo: true, showFeed: false, showStories: false})}}>
                                    Info
                                </div>
                                <div className="user-navbar-lower-tab" ref={this.storiesButtonRef} onClick={() => {this.setButtonUnderline(this.storiesButtonRef, this.infoButtonRef, this.feedButtonRef); this.setState({showInfo: false, showFeed: false, showStories: true})}}>
                                    Stories
                                </div>
                                <div className="user-navbar-lower-tab" ref={this.feedButtonRef} onClick={() => {this.setButtonUnderline(this.feedButtonRef, this.infoButtonRef, this.storiesButtonRef); this.setState({showInfo: false, showFeed: true, showStories: false})}}>
                                    Feed
                                </div>
                            </div>

                            {this.state.showInfo && (

                                <div className="user-info-container">
                                    {this.state.userInfo.isAdmin && (
                                        <div className="admin-container">
                                            <img src={adminIcon} alt="User is admin" className='admin-icon'/>
                                            <b>This user is an admin on the site.</b>
                                        </div>
                                    )}
                                    <div className="user-info-section-title">
                                        About
                                    </div>
                                    <div className="about-row">
                                        {this.state.userInfo.description.length != 0 && (
                                            <>{this.state.userInfo.description}</>
                                        )}
                                        {this.state.userInfo.description.length == 0 && (
                                            <>No bio to display yet!</>
                                        )}
                                    </div>

                                    {this.props.currentUser != null && this.props.currentUser.isAdmin && (
                                        <>
                                            <div className="user-info-section-title">
                                                Email (admin)
                                            </div>
                                            <p className="pad-bottom-10">{this.state.userInfo.email}</p>
                                        </>
                                    )}


                                    <div className="user-info-section-title">
                                        Links
                                    </div>
                                    {this.state.userInfo.otherLink != null && this.state.userInfo.otherLink.length != 0 && (
                                        <div className="one-link-row">
                                            <img src={linkIcon} alt="link icon" className="social-icon"/>
                                            <a className="profile-link" href={this.state.userInfo.otherLink}>{this.state.userInfo.otherLink}</a>
                                        </div>
                                    )}
                                    {this.state.userInfo.instagramLink != null && this.state.userInfo.instagramLink.length != 0 && (
                                        <div className="one-link-row">
                                            <img src={instaIcon} alt="instagram icon" className="social-icon"/>
                                            <a className="profile-link" href={this.state.userInfo.instagramLink}>{this.state.userInfo.instagramLink}</a>
                                        </div>
                                    )}
                                    {this.state.userInfo.twitterLink != null && this.state.userInfo.twitterLink.length != 0 && (
                                        <div className="one-link-row">
                                            <img src={twitterIcon} alt="twitter icon" className="social-icon"/>
                                            <a className="profile-link" href={this.state.userInfo.twitterLink}>{this.state.userInfo.twitterLink}</a>
                                        </div>
                                    )}
                                    {this.state.userInfo.twitterLink == null && this.state.userInfo.otherLink == null && this.state.userInfo.instagramLink == null && (
                                        <div className="about-row">
                                            No links to display yet!
                                        </div>
                                    )}
                                </div>
                            )}

                            {this.state.showStories && (
                                <div className="container">
                                                    <div className="row">
                                                        {this.state.userStories != null && this.state.userStories.map((story, index) => {
                                                            if (!story.allStoryInformation.publishAnon) {

                                                                let colWidth = 3;
                                                                if (window.screen.width < 977) {
                                                                    colWidth = 4;
                                                                }

                                                                return (
                                                                        <StoryPreview story={story} userInfo={this.state.userInfo} colWidth={colWidth} />
                                                                    )
                                                            }
                                                        })}
                                                        {this.state.userStories != null && this.state.userStories.length == 0 && (
                                                            <div className="no-stories">
                                                                <h3>No stories to display by this user yet!</h3>
                                                            </div>
                                                        )}
                                                </div>
                                </div>
                            )}

                            {this.state.showFeed && (
                                <div className="feed-list">
                                    {this.state.userStories.map((story, i) => {
                                        return (
                                            <div className="feed-list-item">
                                                Published the story <b className="blue-highlight">{story.allStoryInformation.title}</b> on <b>{new Date(story.timestamp).toLocaleDateString("en-US")}</b>.
                                            </div>
                                        )
                                    })}
                                    <div className="feed-list-item">
                                        Joined Strangest Fiction on {}!                                            
                                    </div>
                                </div>
                            )}
                            
                    </div>
                    )}
                    {this.state.userInfo == null && (
                        <div className="story-not-found-container">
                            <h1>User not found!</h1>
                            <p>We couldn't find the user you were looking for. If the account was just created, try <a className="refresh-users-link" onClick={() => {getInitialDataFB(this.props.dispatch)}}>refreshing here.</a></p>
                            <Button variant="primary" size="lg" onClick={() => {this.props.history.push("/")}}>Return to Home</Button>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        currentUser: state.primary.currentUser
    }
}

export default connect (mapStateToProps) (UserPage)