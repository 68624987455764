import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import SkinnySpookyEyes from '../new_images/branding/skinny-spooky-eyes.jpg';
import Campfire from '../new_images/bonfire.png';
import { css } from '@emotion/css';
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import volumeOneImage from '../new_images/publications/AnthologyCover.jpg';
import volumeTwoImage from '../new_images/publications/AnthologyCoverV2.jpg';
import StrangestFictionPoster from '../new_images/branding/StrangestFictionFinalVersion.png';
import UFOImage from '../new_images/ufo.png';

const HomeCarousel = () => {

    let blockHeight = "300px";
    if (window.screen.width < 500) blockHeight = "350px";

    return (
        <div className={`post_gallary_area mb40 primay_bg dark-v`}>
            <Carousel>
                <Carousel.Item interval={7000}>
                    <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}"); height: ${blockHeight};`}>
                        <div className={css`display: flex; justify-content: center `}>
                            <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                                CAMPFIRE STORIES COLLECTION
                            </div>
                        </div>
                        <div className={css`display: flex;  justify-content: center;`}>
                            <div className={css`max-width: 250px;`}>
                                <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                    The first ever Strangest Fiction - Campfire Stories Collection is coming summer 2025! Stay on the lookout for these spooky tales.
                                </div>
                            </div>
                            <img src={Campfire} alt="temp icon" className={css`
                                width: 150px;
                                height: 150px;
                                margin-left: 10px;
                            `}/>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={7000}>
                    <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}"); height: ${blockHeight};`}>
                        <div className={css`display: flex; justify-content: center `}>
                            <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                                SF ANTHOLOGY V2 NOW AVAILABLE!
                            </div>
                        </div>
                        <div className={css`display: flex;  justify-content: center;`}>
                            <div className={css`max-width: 250px;`}>
                                <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                    We are beyond thrilled to announce that our second short story collection is now available for purchase in eBook and Paperback! Click below to secure your copy today.
                                </div>
                                <Link to="/publications/anthology-vol-2" className={css`width: 100%;`}>
                                    <Button variant="primary" className="email-signup-button">
                                        Learn More
                                    </Button>
                                </Link>
                            </div>
                            <img src={volumeTwoImage} alt="Anthology Volume 2 Image" className={css`
                                width: 150px;
                                margin-left: 15px;
                            `}/>
                        </div>
                    </div>
                </Carousel.Item>
                {/* <Carousel.Item interval={7000}>
                    <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}"); height: ${blockHeight};`}>
                        <div className={css`display: flex; justify-content: center `}>
                            <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                                NOW RECRUITING FOR SF ANTHOLOGY V2
                            </div>
                        </div>
                        <div className={css`display: flex;  justify-content: center;`}>
                            <div className={css`max-width: 250px;`}>
                                <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                    We are happy to announce that we are now accepting submissions for the Strangest Fiction Anthology - Volume 2. Use the simple submission form by clicking the link below to submit your story today!
                                </div>
                                <Link to="/submit/anthology-v2" className={css`width: 100%;`}>
                                    <Button variant="primary" className="email-signup-button">
                                        Submit Story
                                    </Button>
                                </Link>
                            </div>
                            <img src={StrangestFictionPoster} alt="temp icon" className={css`
                                width: 150px;
                                height: 150px;
                                margin-left: 10px;
                            `}/>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={7000}>
                    <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}"); height: ${blockHeight};`}>
                        <div className={css`display: flex; justify-content: center `}>
                            <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                                SCIENCE FICTION STORY COMPETITION
                            </div>
                        </div>
                        <div className={css`display: flex;  justify-content: center;`}>
                            <div className={css`max-width: 250px;`}>
                                <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                    We are now accepting submissions for our September 2023 Science Fiction story competiton. Enter for a chance to win $25, a free copy of the anthology, and featured status on all platforms.
                                </div>
                                <Link to="/competitions/september-2023" className={css`width: 100%;`}>
                                    <Button variant="primary" className="email-signup-button">
                                        Submit Story
                                    </Button>
                                </Link>
                            </div>
                            <img src={UFOImage} alt="temp icon" className={css`
                                width: 150px;
                                height: 150px;
                                margin-left: 10px;
                            `}/>
                        </div>
                    </div>
                </Carousel.Item> */}
                {/* <Carousel.Item interval={7000}>
                    <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}"); height: ${blockHeight};`}>
                        <div className={css`display: flex; justify-content: center `}>
                            <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                                DECEMBER HORROR STORY COMPETITION
                            </div>
                        </div>
                        <div className={css`display: flex;  justify-content: center;`}>
                            <div className={css`max-width: 250px;`}>
                                <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                    Congratulations to Michael Onofrio on winning our December 2022 Horror Story Competition with his intriguing and disturbing short story "The Mirror"!
                                </div>
                                <Link to="/story/rtWOke2Je7dSAD7lCQ7l" className={css`width: 100%;`}>
                                    <Button variant="primary" className="email-signup-button">
                                        Read Story
                                    </Button>
                                </Link>
                            </div>
                            <img src={StrangestFictionPoster} alt="temp icon" className={css`
                                width: 150px;
                                height: 150px;
                                margin-left: 10px;
                            `}/>
                        </div>
                    </div>
                </Carousel.Item> */}
                {/* <Carousel.Item interval={7000}>
                    <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}"); height: ${blockHeight};`}>
                        <div className={css`display: flex; justify-content: center `}>
                            <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                                DECEMBER HORROR STORY COMPETITION
                            </div>
                        </div>
                        <div className={css`display: flex;  justify-content: center;`}>
                            <div className={css`max-width: 250px;`}>
                                <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                    Missing Halloween already? Submit any short horror story for a chance to win $50 and to be featured on all Strangest Fiction platforms! The competition is free, click to learn more.
                                </div>
                                <Link to="/competitions/december-2022" className={css`width: 100%;`}>
                                    <Button variant="primary" className="email-signup-button">
                                        Learn More
                                    </Button>
                                </Link>
                            </div>
                            <img src={StrangestFictionPoster} alt="temp icon" className={css`
                                width: 150px;
                                height: 150px;
                                margin-left: 10px;
                            `}/>
                        </div>
                    </div>
                </Carousel.Item> */}
                <Carousel.Item interval={7000}>
                    <div className={css`padding: 10px; background-image: url("${SkinnySpookyEyes}"); height: ${blockHeight};`}>
                        <div className={css`display: flex; justify-content: center `}>
                            <div className={css`padding-bottom: 15px; font-size: 25px; font-weight: bold;`}>
                                SF ANTHOLOGY V1
                            </div>
                        </div>
                        <div className={css`display: flex;  justify-content: center;`}>
                            <div className={css`max-width: 250px;`}>
                                <div className={css`font-size: 18px; line-height: 1.2; padding-bottom: 10px;`}>
                                    Check out our first ever short story collection which is available for purchase in eBook and Softcover! Click below to secure your copy today.
                                </div>
                                <Link to="/publications/anthology-vol-1" className={css`width: 100%;`}>
                                    <Button variant="primary" className="email-signup-button">
                                        Learn More
                                    </Button>
                                </Link>
                            </div>
                            <img src={volumeOneImage} alt="Anthology Volume 1 Image" className={css`
                                width: 150px;
                                margin-left: 15px;
                            `}/>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>

            <div className={css`display: flex; justify-content: center; padding-top: 50px; padding-bottom: 10px;`}>
                    <div className={css`max-width: 500px; text-align: center;`}>
                        <h2>GREETINGS</h2>
                        <div className={css`font-size: 25px; padding-top: 10px;`}>
                            Strangest Fiction is an independent publishing house that invests in writers of all backgrounds and continuously develops itself as a gateway to the traditional publishing world of horror, thriller, fantasy, and science fiction writing.
                        </div>
                    </div>
            </div>
        </div>
  );
}

export default HomeCarousel;