import React, {Fragment} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import './CreateAccountModal.css';
import { getDBRef } from '../FirebaseActions';

export default class DeleteStoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {        
        };
        this.db = getDBRef();
    }

    deleteStory() {
        //console.log(this.props.storyToDelete);

        this.db.collection("users").doc(this.props.currentUser.uid).collection("stories").doc(this.props.storyToDelete.id).delete().then(() => {
            //console.log("Document successfully deleted!");
        }).catch((error) => {
            //console.error("Error removing document: ", error);
        });

        this.db.collection("users").doc(this.props.currentUser.uid).update({
            numstories: (this.props.currentUser.numstories - 1)
        })

        this.db.collection("all-stories").doc(this.props.storyToDelete.id).delete().then(() => {
            //console.log("Document successfully deleted!");
            this.props.closeModalStoryDeleted();
        }).catch((error) => {
            //console.error("Error removing document: ", error);
        });
    }

    render() {
        return ( 
            <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="center-content">
                        <h3>
                            Delete Story
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-content">
                        <div className="short-description">
                            Are you sure you would like to delete the story <b>{this.props.storyToDelete.allStoryInformation.title}</b>? This action is irreversible.
                        </div>
                    </div>
                    <div className="center-content">
                        <Button variant="secondary" size="lg" className="pad-right-10" onClick={() => {this.props.closeModal()}}>
                            Close
                        </Button>
                        <Button variant="primary" size="lg" className="" onClick={() => {this.deleteStory()}}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}