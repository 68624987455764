import React from 'react';
import InstagramIcon from '../new_images/sm-logos/instagram-colored-icon.png';
import TwitterIcon from '../new_images/sm-logos/twitter-colored-icon.png';

export default class InstagramLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    render() {
        return (
            <div className="social-media-box-container">
                <h2 className={`widget-title follow-us-title`}>Follow Us</h2>
                <a href="https://www.instagram.com/strangest_fiction/" alt="strangest fiction insta"><img src={InstagramIcon} alt="instagram icon" className="large-instagram"/></a>
                <a href="https://twitter.com/StrangestFict" alt="strangest fiction twitter"><img src={TwitterIcon} alt="twitter icon" className="large-instagram"/></a>
            </div>
        );
    }
};